<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="1000px"
      v-if="resolutionScreen >= 500"
    >
      <v-card style="height:650px;">
        <v-card-title
          >{{ $t("toolbar.specialupload") }} <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-upload</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions class="mt-2" v-if="statusuploading === true">
          <v-btn
            elevation="1"
            fab
            x-small
            class="text-center ml-2"
            :color="$vuetify.theme.dark ? 'white' : color.theme"
            @click="cleardataNoclose()"
            :disabled="createprogress"
          >
            <v-icon
              :color="$vuetify.theme.dark ? '#989898' : 'white'"
              dark
              size="16"
              >keyboard_backspace</v-icon
            >
          </v-btn>
          <v-spacer></v-spacer>
          <v-chip outlined class="mr-2"
            >{{
              $t("uploadfile.uploadingfile") + " " + length_uploading + " "
            }}
            {{
              length_uploading > 1
                ? $t("uploadfile.files")
                : $t("uploadfile.file")
            }}</v-chip
          >
          <v-chip outlined class="mr-2"
            >{{ $t("uploadfile.success") + " " + length_success + " " }}
            {{
              length_success > 1
                ? $t("uploadfile.files")
                : $t("uploadfile.file")
            }}</v-chip
          >
          <v-chip outlined class="mr-2"
            >{{ $t("uploadfile.fail") + " " + length_fail + " " }}
            {{
              length_fail > 1 ? $t("uploadfile.files") : $t("uploadfile.file")
            }}</v-chip
          >
          <v-chip outlined class="mr-2"
            >{{ $t("uploadfile.duplicate") + " " + length_duplicate + " " }}
            {{
              length_duplicate > 1
                ? $t("uploadfile.files")
                : $t("uploadfile.file")
            }}</v-chip
          >
        </v-card-actions>
        <v-card-text v-if="statusuploading === false">
          <v-layout class="mt-6">
            <v-flex
              xl7
              lg7
              md7
              class="px-8"
              style="overflow-y: scroll; max-height: 600px"
            >
              <div class="mb-4 text-left">
                <v-layout row wrap class="mx-1">
                  <v-flex lg8 xl8 md7>
                    <v-autocomplete
                      :items="datatemplate"
                      solo
                      v-model="selectdatatemplate"
                      :label="$t('smartinput.template')"
                      dense
                      prepend-icon="layers"
                      item-value="template_id"
                      item-text="template_name"
                      :color="color.theme"
                      outlined
                      :item-color="`${color.theme}`"
                      return-object
                      @change="
                        $v.$reset(),
                          getDetailsTemplate(
                            selectdatatemplate.id,
                            selectdatatemplate.structure_data,
                            selectdatatemplate
                          )
                      "
                      :no-data-text="$t('smartinput.no_data_templates')"
                    >
                      <!-- fn_check_smup_specific() ? '' :  -->
                      <template :color="color.theme" v-slot:item="{ item }">
                        <span style="">{{ item.template_name }}</span>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    class="text-right mt-1"
                    lg4
                    xl4
                    md5
                    v-if="totalstackfile <= 0"
                  >
                    <v-chip outlined color="#FFA000">
                      <p
                        style="font-size: 16px; font-weight: 600;"
                        class="mt-4"
                      >
                        {{ $t("direct_upload.nofilewaitupload") }}
                      </p>
                    </v-chip>
                  </v-flex>
                  <v-flex class="text-right mt-1" lg4 xl4 md5 v-else>
                    <v-chip color="success">
                      <p style="font-size: 16px" class="mt-4">
                        <span style="font-weight: 600;">
                          {{ totalstackfile }}
                        </span>
                        {{
                          totalstackfile > 1
                            ? $t("direct_upload.files")
                            : $t("direct_upload.file")
                        }}
                      </p>
                    </v-chip>
                  </v-flex>
                  <!-- <v-flex
                    v-if="datatemplate.length > 0"
                    lg6
                    xl6
                    md6
                    class="mt-n1 text-left"
                  >
                    <v-checkbox
                      class="mt-0 "
                      v-model="checktemplatefolder"
                      :label="$t('smartinput.selectfoldertemplate')"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex
                    v-if="checktemplatefolder === true"
                    lg6
                    xl6
                    md6
                    class="mt-n1 text-right"
                  >
                    <v-btn
                      small
                      @click="openselectmainfoldertemplate = true"
                      color="success"
                      elevation="2"
                      rounded
                    >
                      <v-icon>mdi-plus</v-icon>{{ $t("smartinput.select") }}
                    </v-btn>
                  </v-flex> -->
                  <v-flex v-if="datatemplate.length > 0" lg12 xl12 md12>
                    <v-card-actions class="mx-n3 mt-n4">
                      <div
                        class="pa-1 px-3 text-left"
                        :style="
                          $vuetify.theme.dark
                            ? 'background-color:#474747'
                            : 'background-color:#f5f5f5'
                        "
                        style="width: 100%; border-radius: 10px;"
                      >
                        <!-- <v-skeleton-loader
                          v-if="
                            loadingdatatemplate === true &&
                              checktemplatefolder === true
                          "
                          :style="
                            $vuetify.theme.dark
                              ? 'background-color:#474747'
                              : 'background-color:#f5f5f5'
                          "
                          type="text"
                        ></v-skeleton-loader> -->
                        <v-skeleton-loader
                          v-if="loadingdatatemplate === true"
                          :style="
                            $vuetify.theme.dark
                              ? 'background-color:#474747'
                              : 'background-color:#f5f5f5'
                          "
                          type="text"
                        ></v-skeleton-loader>
                        <span
                          v-else
                          class="font-weight-bold"
                          style="font-size: 14px;"
                        >
                          <!-- <p v-if="checktemplatefolder === true" class="mb-0">
                            {{ $t("smartinput.foldertemplate") }}
                            {{
                              datafoldertemplate.path === undefined
                                ? $t("smartinput.notyetchosen")
                                : datafoldertemplate.folder_name
                            }}
                          </p> -->
                          {{ $t("smartinput.fileto") }}
                          <span>
                            <!-- {{ structure_symbol_data }} -->
                            {{ structure_data }}
                          </span>
                          <br>
                          <!-- {{ $t("smartinput.exemplefilename") }}
                          <span>
                            {{ currenttemplate.structure_file_name }}
                          </span> -->
                          <p v-if="currenttemplate.structure_file_name !== '' && currenttemplate.structure_file_name !== null" class="mb-0">
                            {{ $t("smartinput.exemplefilename") }}
                            {{ currenttemplate.structure_file_name }}
                          </p>
                        </span>
                      </div>
                    </v-card-actions>
                  </v-flex>
                </v-layout>
              </div>
              <!-- upload layout -->
              <div
                :style="
                  dropzoneuploadfiles +
                    'border-radius: 24px; position: relative;'
                "
                class="text-center mt-4 mx-auto"
                id="dropzoneuploadfiles"
                v-if="totalstackfile > 0"
              >
                <div
                  @click="ImportFiles"
                  id="inputuploadfiles"
                  style="opacity: 0;"
                >
                  <input
                    ref="importdoc"
                    type="file"
                    @change="fnStackFile"
                    multiple
                    class="d-none"
                  />
                </div>
                <v-overlay :value="stackfileloading" absolute>
                  <v-progress-circular indeterminate></v-progress-circular>
                </v-overlay>
                <v-list
                  class="pt-6 mr-n4"
                  dense
                  :style="
                    layouthoveruploadfiles +
                      'z-index:3; overflow-y: scroll; height: 320px;'
                  "
                  id="layouthoveruploadfiles"
                >
                  <v-list-item
                    v-for="item in arraydataupload"
                    :key="item.id"
                    class="my-n4"
                    id="listStackFiles"
                  >
                    <v-list-item-action class="mr-4" id="listStackFiles">
                      <v-icon large :color="item.icon[1]" id="listStackFiles">{{
                        item.icon[0]
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content
                      class="mr-n2 pl-2 text-left"
                      id="listStackFiles"
                    >
                      <v-list-item-title
                        style="font-size: 14px;"
                        id="listStackFiles"
                      >
                        {{ item.name + "." + item.typefile }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        id="listStackFiles"
                        style="color:red;"
                        v-if="
                          item.errorduplicate === 'nonapi' ||
                            item.errorduplicate === 'api'
                        "
                      >
                        {{ $t("uploadfile.fileduplicate") }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action id="listStackFiles" class="text-right">
                      <!-- ปุ่ม cancel upload -->
                      <v-icon
                        id="listStackFiles"
                        class="ml-2 pr-4"
                        @click="fnDeleteStackFile(item)"
                        color="red"
                        >mdi-close</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <div class="mb-n6" id="listStackFiles">
                  <span
                    id="titleTextUploadtitle"
                    class="mr-2"
                    style="font-size:16px;"
                    >{{ $t("uploadfile.clicktouploadnew") }}</span
                  >
                </div>
                <dir>
                  <span id="titleTextUploadsubtitle" style="font-size:14px;">{{
                    $t("uploadfile.maxsizeuploadtext")
                  }}</span>
                </dir>
              </div>
              <div
                v-else
                :style="uploadLayout + dropzoneuploadfiles"
                class="text-center mt-3 pt-12 pb-6  mx-auto"
                @click="ImportFiles"
                id="dropzoneuploadfiles"
              >
                <div
                  :style="layouthoveruploadfiles"
                  id="layouthoveruploadfiles"
                  style="line-height: 1.5; display: inline-block; vertical-align: middle;"
                >
                  <input
                    ref="importdoc"
                    class="d-none"
                    type="file"
                    @change="fnStackFile"
                    multiple
                    style="display:none"
                  />
                  <v-img
                    max-width="240"
                    max-height="210"
                    class="mx-auto"
                    :src="require('@/assets/img/uploadfiles.png')"
                  />
                  <span id="titleTextUploadtitle" style="font-size:16px">{{
                    $t("uploadfile.clicktouploadnew")
                  }}</span>
                  <br />
                  <span id="titleTextUploadsubtitle" style="font-size:14px">{{
                    $t("uploadfile.maxsizeuploadtext")
                  }}</span>
                  <br />
                  <br />
                </div>
              </div>
            </v-flex>
            <v-divider vertical></v-divider>
            <!-- !listbizsymbolsmartupload -->
            <v-flex v-if="detailstemplate.aspect_data !== undefined" xl5 lg5 md5>
              <div
                v-if="
                  loadingdatatemplate === false &&
                    (detailstemplate.aspect_data.length > 0 &&
                    detailstemplate.aspect_data.filter(item => item.readfilename === 'N').length > 0
                    )
                "
              >
                <v-list
                  dense
                  v-for="(item, i) in detailstemplate.aspect_data"
                  :key="i"
                  :class="item.readfilename === 'N' ? 'mb-n6' : 'mb-n4'"
                >
                  <v-list-item v-if="item.readfilename === 'N'" class="mt-n3">
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size:16px; line-height: 24px"
                      >
                        <span
                          v-if="item.aspect_null === 'Y'"
                          style="font-size:18px; line-height: 24px; color: red;"
                          >*</span
                        >
                        {{ item.aspect_label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.readfilename === 'N'" class="mt-n1">
                    <v-list-item-content
                      v-if="
                        item.aspect_type === 'DROPDOWN' &&
                          item.status_value === 'Y'
                      "
                    >
                      <v-select
                        v-model="item.value"
                        :items="item.aspect_fix_value"
                        outlined
                        dense
                        item-color="color.theme"
                        :color="color.theme"
                        prepend-icon="mdi-format-list-bulleted-type"
                        :item-value="item.aspect_fix_value[0]"
                      >
                        <template v-slot:item="{ item }">{{
                          item === "notselecttemplate"
                            ? $t("smartinput.notchose")
                            : item
                        }}</template>
                        <template v-slot:selection="{ item }">{{
                          item === "notselecttemplate"
                            ? $t("smartinput.notchose")
                            : item
                        }}</template>
                      </v-select>
                    </v-list-item-content>
                    <v-list-item-content
                      v-else-if="
                        item.aspect_type === 'DATE' && item.status_value === 'Y'
                      "
                    >
                      <v-dialog
                        persistent
                        v-model="item.menudate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :color="color.theme"
                            prepend-icon="event"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                            outlined
                            clearable
                            @click:clear="
                              item.aspect_null === 'Y'
                                ? (item.fixvalue = '')
                                : (item.value = '')
                            "
                            :value="item.showdate"
                            :rules="aspectDataRequired"
                            :error-messages="item.erroraspectmessages"
                            @input="
                              item.aspect_null === 'Y'
                                ? $v.detailstemplate.aspect_data.$each[
                                    i
                                  ].fixvalue.$touch()
                                : $v.detailstemplate.aspect_data.$each[
                                    i
                                  ].value.$touch()
                            "
                            @blur="
                              item.aspect_null === 'Y'
                                ? $v.detailstemplate.aspect_data.$each[
                                    i
                                  ].fixvalue.$touch()
                                : $v.detailstemplate.aspect_data.$each[
                                    i
                                  ].value.$touch()
                            "
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="item.showdate"
                          :locale="$t('default')"
                          @input="
                            (item.menudate = false),
                              item.aspect_null === 'Y'
                                ? (item.fixvalue = changeDateFormat(item))
                                : (item.value = changeDateFormat(item))
                          "
                          :color="color.theme"
                        >
                          <v-spacer></v-spacer>
                          <v-btn
                            dark
                            color="error"
                            @click="item.menudate = false"
                          >
                            {{ $t("sharefile.closedatepiker") }}
                          </v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-list-item-content>
                    <v-list-item-content
                      v-else-if="
                        item.aspect_type === 'TEXT' && item.status_value === 'Y'
                      "
                    >
                      <v-text-field
                        v-if="item.aspect_null === 'Y'"
                        height="35px"
                        class="textFieldClass"
                        v-model.trim="item.fixvalue"
                        maxlength="50"
                        outlined
                        dense
                        :color="color.theme"
                        prepend-icon="mdi-format-text"
                        :disabled="item.status_fix === 'Y'"
                        :rules="aspectDataRequired"
                        :error-messages="item.erroraspectmessages"
                        @input="
                          $v.detailstemplate.aspect_data.$each[
                            i
                          ].fixvalue.$touch()
                        "
                        @blur="
                          $v.detailstemplate.aspect_data.$each[
                            i
                          ].fixvalue.$touch()
                        "
                      >
                      </v-text-field>
                      <v-text-field
                        v-else
                        height="35px"
                        class="textFieldClass"
                        v-model.trim="item.value"
                        maxlength="50"
                        outlined
                        dense
                        :color="color.theme"
                        :disabled="item.status_fix === 'Y'"
                        prepend-icon="mdi-format-text"
                        :rules="aspectDataRequired"
                        :error-messages="item.erroraspectmessages"
                        @input="
                          $v.detailstemplate.aspect_data.$each[i].value.$touch()
                        "
                        @blur="
                          $v.detailstemplate.aspect_data.$each[i].value.$touch()
                        "
                      >
                      </v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-divider v-if="listaspectsymbol.length > 0"></v-divider>
                <v-list
                  dense
                  v-for="item in listaspectsymbol"
                  :key="item.aspect_name"
                  class="mb-3 ml-4 pr-10 mt-3"
                  :style="
                    $vuetify.theme.dark
                      ? 'background-color:#474747'
                      : 'background-color:#f5f5f5'
                  "
                  style="width: 90%; border-radius: 10px;"
                >
                  <v-list-item class="" style="">
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size:16px; line-height: 24px"
                      >
                        {{ item.aspect_label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="mt-n4">
                    <v-list-item-content>
                      <span>{{
                        $t("smartinput.fomatnamefile") +
                          " : " +
                          item.aspect_name +
                          item.aspect_symbol +
                          "XXXXXXXXXX"
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
              <div
                v-else-if="
                  loadingdatatemplate === false &&
                    detailstemplate.aspect_data.length === 0 ||
                    detailstemplate.aspect_data.filter(item => item.readfilename === 'N').length === 0
                "
                style="overflow:hidden;"
              >
                <v-layout row wrap class="text-center mt-12">
                  <v-flex class="text-center">
                    <span style="font-size: 24px">
                      {{ $t("smartinput.emptry_aspect") }}
                    </span>
                  </v-flex>
                </v-layout>
              </div>
              <div v-else style="text-align: center;">
                <div
                  style="display: inline-block;vertical-align: middle;line-height: 1.5;"
                >
                  <v-progress-circular
                    :width="10"
                    :color="color.theme"
                    indeterminate
                    :size="100"
                  ></v-progress-circular>
                </div>
              </div>
            </v-flex>
            <!-- <v-flex xl5 lg5 md5 v-else style="overflow-y: scroll; max-height: 600px">
              <div
                v-if="
                  loadingdatatemplate === false &&
                    detailstemplate.aspect_data.length > 0
                "
              >
                <v-list
                  dense
                  v-for="(item, i) in detailstemplate.aspect_data"
                  :key="i"
                  class="mb-3 ml-4 pr-10"
                  :style="
                    $vuetify.theme.dark
                      ? 'background-color:#474747'
                      : 'background-color:#f5f5f5'
                  "
                  style="width: 90%; border-radius: 10px;"
                >
                  <v-list-item class="" style="">
                    <v-list-item-content>
                      <v-list-item-title
                        style="font-size:16px; line-height: 24px"
                      >
                        {{ item.aspect_label }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item class="mt-n4">
                    <v-list-item-content>
                      <span>{{
                        $t("smartinput.fomatnamefile") +
                          " : " +
                          item.aspect_name +
                          item.aspect_symbol +
                          "XXXXXXXXXX"
                      }}</span>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
              <div
                v-else-if="
                  loadingdatatemplate === false &&
                    detailstemplate.aspect_data.length === 0
                "
                style="overflow:hidden;"
              >
                <v-layout row wrap class="text-center mt-12">
                  <v-flex class="text-center">
                    <span style="font-size: 24px">
                      {{ $t("smartinput.emptry_aspect") }}
                    </span>
                  </v-flex>
                </v-layout>
              </div>
              <div v-else style="text-align: center;">
                <div
                  style="display: inline-block;vertical-align: middle;line-height: 1.5;"
                >
                  <v-progress-circular
                    :width="10"
                    :color="color.theme"
                    indeterminate
                    :size="100"
                  ></v-progress-circular>
                </div>
              </div>
            </v-flex> -->
          </v-layout>
        </v-card-text>
        <v-card-text v-else>
          <div>
            <v-divider></v-divider>
            <v-list
              two-line
              v-for="(item, i) in temparraydataupload"
              :key="item.id"
            >
              <v-list-item>
                <v-list-item-action class="mr-4 ml-n4">
                  <v-icon size="55" :color="item.icon[1]">{{
                    item.icon[0]
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content class="mr-n2">
                  <v-list-item-title style="font-size: 14px;line-height:24px;">
                    <v-text-field
                      v-if="item.checkfailformat === true"
                      v-model="item.name"
                      outlined
                      dense
                      :color="color.theme"
                      :value="item.name"
                      :suffix="'.' + item.typefile"
                      :rules="filenameRequired"
                      :error-messages="item.errorfailformat"
                      @input="$v.temparraydataupload.$each[i].name.$touch()"
                      @blur="$v.temparraydataupload.$each[i].name.$touch()"
                    >
                    </v-text-field>
                    <span v-else>{{ item.name + "." + item.typefile }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px;">
                    <p>
                      {{ calculatesize(item.size) }}
                      <span v-if="item.status === 'start'" class="float-right"
                        >{{ item.value }} %</span
                      >
                      <span
                        v-else-if="item.status === 'success'"
                        style="color:green;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'fail'"
                        style="color:red;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'skip'"
                        style="color:red;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'cancel'"
                        style="color:red;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                      <span
                        v-else-if="item.status === 'duplicate'"
                        style="color:#fb8c00;"
                        class="float-right"
                        >{{ item.msg }}</span
                      >
                    </p>
                  </v-list-item-subtitle>
                  <v-progress-linear
                    :color="item.colorupload"
                    :value="item.value"
                  ></v-progress-linear>
                </v-list-item-content>
                <v-list-item-action
                  class="text-right mr-n6"
                  style="width: 90px"
                >
                  <!-- ปุ่ม replace -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicate('replace', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'primary'"
                    outlined
                    small
                    width="80px"
                  >
                    {{ $t("fileduplicate.filereplace") }}
                  </v-btn>
                  <!-- ปุ่ม copy -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicate('copy', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'success'"
                    outlined
                    small
                    width="80px"
                    class="mt-1"
                  >
                    {{ $t("fileduplicate.filecopy") }}
                  </v-btn>
                  <!-- ปุ่ม fileskip -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicate('', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'error'"
                    outlined
                    small
                    width="80px"
                    class="mt-1"
                  >
                    {{ $t("fileduplicate.fileskip") }}
                  </v-btn>
                  <!-- ปุ่ม reupload -->
                  <v-icon
                    v-if="item.status === 'fail' || item.status === 'cancel'"
                    @click="(reloadId = item.id), fnLoopUploadFile()"
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    :disabled="
                      item.checkfailformat === false
                        ? ''
                        : item.errorfailformat !== ''
                    "
                    class="ml-8"
                    >mdi-reload</v-icon
                  >
                  <!-- ปุ่ม cancel upload -->
                  <v-icon
                    v-if="item.status === 'start'"
                    class="ml-8"
                    @click="cancelUpload(item.id)"
                    :color="$vuetify.theme.dark ? 'white' : 'error'"
                    >mdi-close</v-icon
                  >
                  <!-- ไอคอนอัปโหลดสำเร็จ -->
                  <v-icon
                    v-if="item.status === 'success'"
                    class="ml-8"
                    :color="$vuetify.theme.dark ? 'white' : 'success'"
                    >mdi-check</v-icon
                  >
                  <!-- ไอคอนยกเลิก -->
                  <v-icon
                    v-if="item.status === 'skip'"
                    class="ml-8"
                    :color="$vuetify.theme.dark ? 'white' : 'red'"
                    >mdi-cancel</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="red"
            class="white--text"
            @click="
              checkstatusupload === false
                ? ($emit('close'), $emit('opendragselect'))
                : $emit('closemobile')
            "
            :disabled="
              createprogress || statusuploading === false
                ? statusuploading
                : length_uploading > 0
            "
            >{{ $t("uploadfile.buttonclose") }}</v-btn
          >
          <v-btn
            color="red"
            class="white--text"
            :disabled="createprogress || statusuploading"
            @click="cleardata()"
          >
            {{ $t("direct_upload.clearfile") }}
          </v-btn>
          <v-btn
            color="#23526c"
            @click="fnLoopUploadFile()"
            class="white--text"
            :disabled="
              createprogress ||
                statusuploading ||
                stackfileloading ||
                datatemplate.length === 0
            "
          >
            {{ $t("direct_upload.uploadfile") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="px-5 pt-4 pb-2" style="overflow-y:hidden">
          <v-layout class="pt-2 pb-1">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{ $t("uploadfile.name") }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="
                  checkstatusupload === false
                    ? ($emit('close'), $emit('opendragselect'))
                    : $emit('closemobile')
                "
                :disabled="createprogress"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
          <v-layout class="pt-4" v-if="statusuploading === true" row wrap>
            <v-flex xs2>
              <v-btn
                elevation="1"
                fab
                x-small
                class="text-center ml-4"
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="cleardataNoclose()"
                :disabled="createprogress"
              >
                <v-icon
                  :color="$vuetify.theme.dark ? '#989898' : 'white'"
                  dark
                  size="20"
                  >keyboard_backspace</v-icon
                >
              </v-btn>
            </v-flex>
            <v-flex xs10 class="text-right pr-2">
              <v-chip outlined
                >{{
                  $t("uploadfile.uploadingfile") + " " + length_uploading + " "
                }}
                {{
                  length_uploading > 1
                    ? $t("uploadfile.files")
                    : $t("uploadfile.file")
                }}</v-chip
              >
              <v-chip outlined
                >{{
                  $t("uploadfile.duplicate") + " " + length_duplicate + " "
                }}
                {{
                  length_duplicate > 1
                    ? $t("uploadfile.files")
                    : $t("uploadfile.file")
                }}</v-chip
              >
            </v-flex>
            <v-flex xs12 class="text-right pr-2">
              <v-chip outlined
                >{{ $t("uploadfile.success") + " " + length_success + " " }}
                {{
                  length_success > 1
                    ? $t("uploadfile.files")
                    : $t("uploadfile.file")
                }}</v-chip
              >
              <v-chip outlined
                >{{ $t("uploadfile.fail") + " " + length_fail + " " }}
                {{
                  length_fail > 1
                    ? $t("uploadfile.files")
                    : $t("uploadfile.file")
                }}</v-chip
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text
          :style="'max-height: ' + maxHeightOnMobile + '; height: auto;'"
        >
          <div class="text-right" v-if="statusuploading === false">
            <v-layout row class="mx-1 mt-2">
              <v-flex>
                <v-autocomplete
                  :items="datatemplate"
                  solo
                  v-model="selectdatatemplate"
                  :label="$t('smartinput.template')"
                  dense
                  prepend-icon="layers"
                  item-value="template_id"
                  item-text="template_name"
                  :color="color.theme"
                  outlined
                  :item-color="`${color.theme}`"
                  return-object
                  @change="
                    $v.$reset(),
                      getDetailsTemplate(
                        selectdatatemplate.id,
                        selectdatatemplate.structure_data,
                        selectdatatemplate
                      )
                  "
                  :no-data-text="$t('smartinput.no_data_templates')"
                >
                  <!-- fn_check_smup_specific() ? '' :  -->
                  <template :color="color.theme" v-slot:item="{ item }">
                    <span style="">{{ item.template_name }}</span>
                  </template>
                </v-autocomplete>
                <!-- <v-select
                  :items="datatemplate"
                  v-model="selectdatatemplate"
                  :label="$t('smartinput.template')"
                  outlined
                  dense
                  prepend-icon="layers"
                  return-object
                  item-value="template_id"
                  item-text="template_name"
                  :color="color.theme"
                  :item-color="`${color.theme}`"
                  @change="$v.$reset(),getDetailsTemplate(selectdatatemplate.template_id)"
                >
                  <template :color="color.theme" v-slot:item="{ item }">
                    <span style="">{{ item.template_name }}</span>
                  </template>
                </v-select> -->
              </v-flex>
            </v-layout>
          </div>
          <div v-if="statusuploading === false">
            <div
              v-if="
                loadingdatatemplate === false &&
                  detailstemplate.aspect_data.length === 0 ||
                  detailstemplate.aspect_data.filter(item => item.readfilename === 'N').length === 0
              "
              style="overflow:hidden;"
            >
              <v-layout row wrap class="text-center my-6 mb-12">
                <v-flex class="text-center">
                  <span style="font-size: 18px">
                    {{ $t("smartinput.emptry_aspect") }}
                  </span>
                </v-flex>
              </v-layout>
            </div>
            <v-expansion-panels v-else>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <p class="pt-2">
                    <span
                      v-if="checkdetailmobile === true"
                      style="font-size:18px; line-height: 24px; color: red;"
                      >*</span
                    >
                    {{ $t("smartinput.tampletedetail") }}
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="loadingdatatemplate === false">
                  <!-- v-if="!listbizsymbolsmartupload.includes(
                  dataAccountActive.business_info.taxid
                  )" -->
                  <div>
                    <v-list
                      dense
                      v-for="(item, i) in detailstemplate.aspect_data"
                      :key="i"
                      :class="item.readfilename === 'N' ? 'mb-3' : ''"
                    >
                      <v-list-item v-if="item.readfilename === 'N'" class="mt-n3">
                        <v-list-item-content>
                          <v-list-item-title
                            style="font-size:16px; line-height: 24px"
                          >
                            <span
                              v-if="item.aspect_null === 'Y'"
                              style="font-size:18px; line-height: 24px; color: red;"
                              >*</span
                            >
                            {{ item.aspect_label }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="item.readfilename === 'N'" class="mt-n1 mb-n6">
                        <v-list-item-content
                          v-if="
                            item.aspect_type === 'DROPDOWN' &&
                              item.status_value === 'Y'
                          "
                        >
                          <v-select
                            v-model="item.value"
                            :items="item.aspect_fix_value"
                            outlined
                            dense
                            item-color="color.theme"
                            :color="color.theme"
                            prepend-icon="mdi-format-list-bulleted-type"
                            :item-value="item.aspect_fix_value[0]"
                          >
                            <template v-slot:item="{ item }">{{
                              item === "notselecttemplate"
                                ? $t("smartinput.notchose")
                                : item
                            }}</template>
                            <template v-slot:selection="{ item }">{{
                              item === "notselecttemplate"
                                ? $t("smartinput.notchose")
                                : item
                            }}</template>
                          </v-select>
                        </v-list-item-content>
                        <v-list-item-content
                          v-else-if="
                            item.aspect_type === 'DATE' &&
                              item.status_value === 'Y'
                          "
                        >
                          <v-dialog
                            persistent
                            v-model="item.menudate"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :color="color.theme"
                                prepend-icon="event"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                dense
                                outlined
                                clearable
                                @click:clear="
                                  item.aspect_null === 'Y'
                                    ? (item.fixvalue = '')
                                    : (item.value = '')
                                "
                                :value="item.showdate"
                                :rules="aspectDataRequired"
                                :error-messages="item.erroraspectmessages"
                                @input="
                                  item.aspect_null === 'Y'
                                    ? $v.detailstemplate.aspect_data.$each[
                                        i
                                      ].fixvalue.$touch()
                                    : $v.detailstemplate.aspect_data.$each[
                                        i
                                      ].value.$touch()
                                "
                                @blur="
                                  item.aspect_null === 'Y'
                                    ? $v.detailstemplate.aspect_data.$each[
                                        i
                                      ].fixvalue.$touch()
                                    : $v.detailstemplate.aspect_data.$each[
                                        i
                                      ].value.$touch()
                                "
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="item.showdate"
                              :locale="$t('default')"
                              @input="
                                (item.menudate = false),
                                  item.aspect_null === 'Y'
                                    ? (item.fixvalue = changeDateFormat(item))
                                    : (item.value = changeDateFormat(item))
                              "
                              :color="color.theme"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                dark
                                color="error"
                                @click="item.menudate = false"
                              >
                                {{ $t("sharefile.closedatepiker") }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-list-item-content>
                        <v-list-item-content
                          v-else-if="
                            item.aspect_type === 'TEXT' &&
                              item.status_value === 'Y'
                          "
                        >
                          <v-text-field
                            v-if="item.aspect_null === 'Y'"
                            height="35px"
                            class="textFieldClass"
                            v-model.trim="item.fixvalue"
                            maxlength="50"
                            outlined
                            dense
                            :color="color.theme"
                            prepend-icon="mdi-format-text"
                            :rules="aspectDataRequired"
                            :error-messages="item.erroraspectmessages"
                            @input="
                              $v.detailstemplate.aspect_data.$each[
                                i
                              ].fixvalue.$touch()
                            "
                            @blur="
                              $v.detailstemplate.aspect_data.$each[
                                i
                              ].fixvalue.$touch()
                            "
                          >
                          </v-text-field>
                          <v-text-field
                            v-else
                            height="35px"
                            class="textFieldClass"
                            v-model.trim="item.value"
                            maxlength="50"
                            outlined
                            dense
                            :color="color.theme"
                            prepend-icon="mdi-format-text"
                            :rules="aspectDataRequired"
                            :error-messages="item.erroraspectmessages"
                            @input="
                              $v.detailstemplate.aspect_data.$each[
                                i
                              ].value.$touch()
                            "
                            @blur="
                              $v.detailstemplate.aspect_data.$each[
                                i
                              ].value.$touch()
                            "
                          >
                          </v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                  <div>
                    <v-list
                      dense
                      v-for="(item, i) in listaspectsymbol"
                      :key="i"
                      class="mb-3"
                      :style="
                        $vuetify.theme.dark
                          ? 'background-color:#474747'
                          : 'background-color:#f5f5f5'
                      "
                      style="width: 100%; border-radius: 10px;"
                    >
                      <v-list-item class="mb-n4">
                        <v-list-item-content>
                          <v-list-item-title
                            style="font-size:16px; line-height: 24px"
                          >
                            {{ item.aspect_label }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item class="mt-0">
                        <v-list-item-content>
                          <span>{{ $t("smartinput.fomatnamefile") }} </span
                          ><br />
                          <span>{{
                            item.aspect_name + item.aspect_symbol + "XXXXXXXXXX"
                          }}</span>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </div>
                </v-expansion-panel-content>
                <v-expansion-panel-content v-else>
                  <div
                    style="display: inline-block;vertical-align: middle;line-height: 1.5;"
                  >
                    <v-progress-circular
                      :width="10"
                      :color="color.theme"
                      indeterminate
                      :size="100"
                    ></v-progress-circular>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div v-if="statusuploading === false">
            <v-layout v-if="datatemplate.length > 0" row wrap class="mx-1 mt-6">
              <!-- <v-flex xs12 class="mt-n1 text-left">
                <v-checkbox
                  style="font-size: 14px;"
                  class="mt-0 "
                  v-model="checktemplatefolder"
                  :label="$t('smartinput.selectfoldertemplate')"
                ></v-checkbox>
              </v-flex>
              <v-flex
                v-if="checktemplatefolder === true"
                xs12
                class="mt-n3 text-right"
              >
                <v-btn
                  small
                  @click="openselectmainfoldertemplate = true"
                  color="success"
                  elevation="2"
                  rounded
                >
                  <v-icon>mdi-plus</v-icon>{{ $t("smartinput.select") }}
                </v-btn>
              </v-flex> -->
              <v-flex lg12>
                <v-card-actions class="mx-n3 mt-0">
                  <div
                    class="pa-1 px-3 text-left"
                    :style="
                      $vuetify.theme.dark
                        ? 'background-color:#474747'
                        : 'background-color:#f5f5f5'
                    "
                    style="width: 100%; border-radius: 10px;"
                  >
                    <!-- <v-skeleton-loader
                      v-if="
                        loadingdatatemplate === true &&
                          checktemplatefolder === true
                      "
                      :style="
                        $vuetify.theme.dark
                          ? 'background-color:#474747'
                          : 'background-color:#f5f5f5'
                      "
                      type="text"
                    ></v-skeleton-loader> -->
                    <v-skeleton-loader
                      v-if="loadingdatatemplate === true"
                      :style="
                        $vuetify.theme.dark
                          ? 'background-color:#474747'
                          : 'background-color:#f5f5f5'
                      "
                      type="text"
                    ></v-skeleton-loader>
                    <span
                      v-else
                      class="font-weight-bold"
                      style="font-size: 14px;"
                    >
                      <!-- <p v-if="checktemplatefolder === true" class="mb-0">
                        {{ $t("smartinput.foldertemplate") }}
                        {{
                          datafoldertemplate.path === undefined
                            ? $t("smartinput.notyetchosen")
                            : datafoldertemplate.folder_name
                        }}
                      </p> -->
                      {{ $t("smartinput.fileto") }}
                      <!-- <span v-if="!listbizsymbolsmartupload.includes(
                        dataAccountActive.business_info.taxid
                      )">
                        {{ structure_data }}
                      </span> -->
                      <span>
                        {{ structure_data }}
                        <!-- {{ structure_symbol_data }} -->
                      </span>
                      <p v-if="currenttemplate.structure_file_name !== '' && currenttemplate.structure_file_name !== null" class="mb-0">
                        {{ $t("smartinput.exemplefilename") }}
                        {{ currenttemplate.structure_file_name }}
                      </p>
                    </span>
                  </div>
                </v-card-actions>
              </v-flex>
            </v-layout>
          </div>
          <div class="text-right" v-if="statusuploading === false">
            <v-layout row wrap class="mx-1 mt-6">
              <v-flex lg12 v-if="totalstackfile <= 0">
                <v-chip outlined color="#FFA000">
                  <p style="font-size: 16px; font-weight: 600;" class="mt-4">
                    {{ $t("direct_upload.nofilewaitupload") }}
                  </p>
                </v-chip>
              </v-flex>
              <v-flex lg12 v-else>
                <v-chip color="success">
                  <p style="font-size: 16px" class="mt-4">
                    <span style="font-weight: 600;">
                      {{ totalstackfile }}
                    </span>
                    {{
                      totalstackfile > 1
                        ? $t("direct_upload.files")
                        : $t("direct_upload.file")
                    }}
                  </p>
                </v-chip>
              </v-flex>
            </v-layout>
          </div>
          <!-- upload layout -->
          <v-card-text
            :style="uploadLayoutMobile"
            class="text-center mt-3"
            v-if="statusuploading === false"
          >
            <div
              style="border-radius: 24px; position: relative;"
              class="text-center mt-4 mx-auto"
              v-if="totalstackfile > 0"
            >
              <div
                @click="ImportFiles"
                id="inputuploadfiles"
                style="opacity: 0;"
              >
                <input
                  ref="importdoc"
                  type="file"
                  @change="fnStackFile"
                  multiple
                  class="d-none"
                />
              </div>
              <v-overlay
                :value="stackfileloading"
                absolute
                class="mx-n4 mt-n3 mb-n5"
              >
                <v-progress-circular indeterminate></v-progress-circular>
              </v-overlay>
              <v-list
                class="pt-2 mx-n4 mt-n6"
                dense
                style="z-index:3; overflow-y: scroll; height: 320px;"
              >
                <v-list-item
                  v-for="item in arraydataupload"
                  :key="item.id"
                  class="my-n4"
                >
                  <v-list-item-action class="mr-4">
                    <v-icon large :color="item.icon[1]">{{
                      item.icon[0]
                    }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content class="mr-n2 text-left">
                    <v-list-item-title style="font-size: 14px;">
                      {{ item.name + "." + item.typefile }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      style="color:red;"
                      v-if="
                        item.errorduplicate === 'nonapi' ||
                          item.errorduplicate === 'api'
                      "
                    >
                      {{ $t("uploadfile.fileduplicate") }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="text-right">
                    <!-- ปุ่ม cancel upload -->
                    <v-icon
                      class="ml-2"
                      @click="fnDeleteStackFile(item)"
                      color="red"
                      >mdi-close</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <div class="mb-0">
                <span
                  id="titleTextUploadtitle"
                  class="mr-2"
                  style="font-size:14px;"
                  >{{ $t("uploadfile.clicktouploadnew") }}</span
                >
              </div>
              <dir>
                <span id="titleTextUploadsubtitle" style="font-size:12px;">{{
                  $t("uploadfile.maxsizeuploadtext")
                }}</span>
              </dir>
            </div>
            <div
              v-else
              :style="uploadLayout"
              class="text-center mt-3 pt-6 pb-6  mx-auto"
              @click="ImportFiles"
            >
              <div>
                <input
                  ref="importdoc"
                  class="d-none"
                  type="file"
                  @change="fnStackFile"
                  multiple
                  style="display:none"
                />
                <v-img
                  id="listStackFiles"
                  max-width="220"
                  max-height="210"
                  class="mx-auto"
                  :src="require('@/assets/img/uploadfiles.png')"
                />
                <span id="titleTextUploadtitle" style="font-size:14px">{{
                  $t("uploadfile.clicktouploadnew")
                }}</span>
                <br />
                <span id="titleTextUploadsubtitle" style="font-size:12px">{{
                  $t("uploadfile.maxsizeuploadtext")
                }}</span>
                <br />
                <br />
              </div>
            </div>
          </v-card-text>
          <div v-else>
            <v-divider></v-divider>
            <v-list
              two-line
              v-for="(item, i) in temparraydataupload"
              :key="item.id"
              class="mt-n2"
            >
              <v-list-item>
                <v-list-item-action class="mr-4 ml-n4">
                  <v-icon large :color="item.icon[1]">{{
                    item.icon[0]
                  }}</v-icon>
                </v-list-item-action>
                <v-list-item-content class="mr-n2">
                  <v-list-item-title style="font-size: 14px;">
                    <v-text-field
                      v-if="item.checkfailformat === true"
                      v-model="item.name"
                      outlined
                      dense
                      :color="color.theme"
                      :value="item.name"
                      :suffix="'.' + item.typefile"
                      :rules="filenameRequired"
                      :error-messages="item.errorfailformat"
                      @input="$v.temparraydataupload.$each[i].name.$touch()"
                      @blur="$v.temparraydataupload.$each[i].name.$touch()"
                    >
                    </v-text-field>
                    <span v-else>{{ item.name + "." + item.typefile }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px;">
                    <p>
                      {{ calculatesize(item.size) }}
                    </p>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span v-if="item.status === 'start'" class="float-right"
                      >{{ item.value }} %</span
                    >
                    <span
                      v-else-if="item.status === 'success'"
                      style="color:green;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'fail'"
                      style="color:red;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'skip'"
                      style="color:red;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'cancel'"
                      style="color:red;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                    <span
                      v-else-if="item.status === 'duplicate'"
                      style="color:#fb8c00;"
                      class="float-right"
                      >{{ item.msg }}</span
                    >
                  </v-list-item-subtitle>
                  <v-progress-linear
                    :color="item.colorupload"
                    class="mt-0"
                    :value="item.value"
                  >
                  </v-progress-linear>
                </v-list-item-content>
                <v-list-item-action
                  class="text-right mr-n4"
                  style="width: 70px"
                >
                  <!-- ปุ่ม replace -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    small
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicate('replace', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'primary'"
                    outlined
                    width="60px"
                  >
                    {{ $t("fileduplicate.filereplace") }}
                  </v-btn>
                  <!-- ปุ่ม copy -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    small
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicate('copy', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'success'"
                    outlined
                    width="60px"
                    class="mt-1"
                  >
                    {{ $t("fileduplicate.filecopy") }}
                  </v-btn>
                  <!-- ปุ่ม fileskip -->
                  <v-btn
                    v-if="
                      (item.status === 'duplicate' &&
                        length_processing === temparraydataupload.length) ||
                        (item.status === 'duplicate' &&
                          checkstatusduplicate === true)
                    "
                    small
                    @click="
                      (checkstatusduplicate = true),
                        setStatusFileDuplicate('', item.id)
                    "
                    :color="$vuetify.theme.dark ? 'white' : 'error'"
                    outlined
                    width="60px"
                    class="mt-1"
                  >
                    {{ $t("fileduplicate.fileskip") }}
                  </v-btn>
                  <!-- ปุ่ม reupload -->
                  <v-icon
                    v-if="item.status === 'fail' || item.status === 'cancel'"
                    @click="
                      (checkstatusduplicate = true),
                        (reloadId = item.id),
                        fnLoopUploadFile()
                    "
                    :color="$vuetify.theme.dark ? 'white' : color.theme"
                    :disabled="
                      item.checkfailformat === false
                        ? ''
                        : item.errorfailformat !== ''
                    "
                    class="ml-7"
                    >mdi-reload</v-icon
                  >
                  <!-- ปุ่ม cancel upload -->
                  <v-icon
                    v-if="item.status === 'start'"
                    class="ml-7"
                    @click="cancelUpload(item.id)"
                    :color="$vuetify.theme.dark ? 'white' : 'error'"
                    >mdi-close</v-icon
                  >
                  <!-- ไอคอนอัปโหลดสำเร็จ -->
                  <v-icon
                    v-if="item.status === 'success'"
                    class="ml-7"
                    :color="$vuetify.theme.dark ? 'white' : 'success'"
                    >mdi-check</v-icon
                  >
                  <!-- ไอคอนยกเลิก -->
                  <v-icon
                    v-if="item.status === 'skip'"
                    class="ml-7"
                    :color="$vuetify.theme.dark ? 'white' : 'red'"
                    >mdi-cancel</v-icon
                  >
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </v-list>
          </div>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            color="red"
            height="45px"
            width="35%"
            outlined
            style="font-size: 16px; font-weight: lighter;"
            :disabled="createprogress || statusuploading"
            @click="cleardata()"
            class="mr-6"
          >
            {{ $t("direct_upload.clearfile") }}
          </v-btn>
          <v-btn
            class="white--text"
            color="#23526c"
            style="font-size: 16px; font-weight: lighter;"
            height="45px"
            width="35%"
            :disabled="
              createprogress ||
                statusuploading ||
                stackfileloading ||
                datatemplate.length === 0
            "
            @click="fnLoopUploadFile()"
          >
            {{ $t("direct_upload.uploadfile") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <div>
      <dialogselectmainfoldertemplate
        :show="openselectmainfoldertemplate"
        :newfolder="datafoldercreate"
        @closenoreload="openselectmainfoldertemplate = false"
        @createfolder="preparingCreateFolder"
        @submitfoldertemplate="addFolderTemplate"
      ></dialogselectmainfoldertemplate>
      <dialogcreatefoldertemplate
        :show="opencreatefoldertemplate"
        @closenoreload="opencreatefoldertemplate = false"
        @createsuccess="preparingNewFolder"
        :parentfolder="foldercreateid"
      ></dialogcreatefoldertemplate>
    </div>
  </v-row>
</template>

<script>
import gbfGenerate from "@/globalFunctions/generateAuthorize";

import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email, helpers } from "vuelidate/lib/validators";
import sizeDataConverter from "@/globalFunctions/sizeDataConverter";
import checkCharacter from "@/globalFunctions/checkCharacter";
import setIconFiles from "@/globalFunctions/setIconFiles";
import format from "date-fns/format";

const dialogselectmainfoldertemplate = () =>
  import("@/components/optional/dialog-selectmainfoldertemplate.vue");
const dialogcreatefoldertemplate = () =>
  import("@/components/upload/dialog-createfoldertemplate.vue");

const SpecialCharacterForFoldernameValidator = helpers.regex(
  "SpecialCharacterForFoldername",
  checkCharacter.checkSpecialCharacterForFoldername()
);
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    detailstemplate: {
      aspect_data: {
        $each: {
          value: { SpecialCharacterForFoldernameValidator },
          fixvalue: { required, SpecialCharacterForFoldernameValidator },
        },
      },
    },
    temparraydataupload: {
      $each: {
        name: { required, SpecialCharacterForFoldernameValidator },
      },
    },
  },
  components: {
    dialogselectmainfoldertemplate,
    dialogcreatefoldertemplate,
  },
  props: [
    "show",
    "parentfolder",
    "switchapiupload",
    "remark",
    "oldactivefile",
    "departmentid",
  ],
  data: function() {
    return {
      openselectmainfoldertemplate: false,
      opencreatefoldertemplate: false,
      createprogress: false,
      selectingimportFiles: false,
      fileImport: null,
      percentfileuploading: 0,
      arraydataupload: [],
      statusuploading: false,
      reloadId: "",
      countupload: 0,
      checkstatusduplicate: false,
      openalertduplicatefile: false,
      checkstatusupload: false,
      totalstackfile: 0,
      temparraydataupload: [],
      stackfileloading: false,
      userdata: {},
      overdragfile: false,
      uniqueidfile: 0,
      loadingdatatemplate: true,
      datatemplate: [],
      detailstemplate: {},
      selectdatatemplate: {},
      checkdetailmobile: false,
      foldercreateid: "",
      datafoldercreate: "",
      mainfolderselect: "",
      checktemplatefolder: false,
      datafoldertemplate: {},
      listbizsymbolsmartupload: ["7222712565067", "0105496000831"],
      checkbizsymbolsmartupload: false,
      listaspectsymbol: [],
      currenttemplate: {},
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataCheckPackage",
    ]),
    // cssVars() {
    //   return {
    //     '--bg-color': this.color.BG,
    //     '--theme-color': this.color.theme
    //   }
    // },
    aspectDataRequired() {
      if (this.loadingdatatemplate === false) {
        for (let i = 0; i < this.detailstemplate.aspect_data.length; i++) {
          if (this.detailstemplate.aspect_data[i].aspect_null === "Y") {
            if (!this.$v.detailstemplate.aspect_data.$each[i].fixvalue.$dirty) {
              this.detailstemplate.aspect_data[i].erroraspectmessages = "";
            } else if (
              this.detailstemplate.aspect_data[i].fixvalue === "" ||
              this.detailstemplate.aspect_data[i].fixvalue === null ||
              this.detailstemplate.aspect_data[i].fixvalue === undefined
            ) {
              this.detailstemplate.aspect_data[i].erroraspectmessages = this.$t(
                "smartinput.emptyaspectnull"
              );
            } else if (
              this.$v.detailstemplate.aspect_data.$each[i].fixvalue.$error
            ) {
              this.detailstemplate.aspect_data[i].erroraspectmessages = this.$t(
                "smartinput.special_aspect_error"
              );
            } else {
              this.detailstemplate.aspect_data[i].erroraspectmessages = "";
            }
          } else {
            if (!this.$v.detailstemplate.aspect_data.$each[i].value.$dirty) {
              this.detailstemplate.aspect_data[i].erroraspectmessages = "";
            } else if (
              this.$v.detailstemplate.aspect_data.$each[i].value.$error
            ) {
              this.detailstemplate.aspect_data[i].erroraspectmessages = this.$t(
                "smartinput.special_aspect_error"
              );
            } else {
              this.detailstemplate.aspect_data[i].erroraspectmessages = "";
            }
          }
        }
      }
    },
    filenameRequired() {
      if (this.loadingdatatemplate === false) {
        for (let i = 0; i < this.temparraydataupload.length; i++) {
          if (this.temparraydataupload[i].checkfailformat === true) {
            console.log(
              "this.$v.temparraydataupload.$each[i]",
              this.$v.temparraydataupload.$each[i]
            );
            if (!this.$v.temparraydataupload.$each[i].name.$dirty) {
              this.temparraydataupload[i].errorfailformat = "";
            } else if (
              this.temparraydataupload[i].name === "" ||
              this.temparraydataupload[i].name === null ||
              this.temparraydataupload[i].name === undefined
            ) {
              this.temparraydataupload[i].errorfailformat = this.$t(
                "smartinput.emptyaspectnull"
              );
            } else if (this.$v.temparraydataupload.$each[i].name.$error) {
              this.temparraydataupload[i].errorfailformat = this.$t(
                "smartinput.special_aspect_error"
              );
            } else {
              this.temparraydataupload[i].errorfailformat = "";
            }
          }
        }
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          this.addEventUploadfiles();
          this.getDataTemplate();
          this.$emit("closeDrag");
        } else {
          this.checkstatusupload = false;
          this.cleardata();
          this.removeEventUploadfiles();
        }
        console.log("this.show", this.show);
        return this.show;
      },
      set(value) {
        if (!value) {
          this.removeEventUploadfiles();
          this.$emit("openDrag");
          this.$emit("close");
        }
      },
    },
    structure_data() {
      try {
        // !this.listbizsymbolsmartupload.includes(this.dataAccountActive.business_info.taxid)
        if (this.detailstemplate) {
          console.log("this.detailstemplate", this.detailstemplate);
          console.log(
            "this.detailstemplate.structure_data.split",
            this.detailstemplate.structure_data.split("/")
          );
          let listsplitstructrue = !this.detailstemplate.structure_data
            ? ""
            : this.detailstemplate.structure_data.split("/");
          let structrue = "";
          for (let index = 0; index < listsplitstructrue.length; index++) {
              let texttest = "";
              for (
                let indexid = 0;
                indexid < this.detailstemplate.aspect_data.length;
                indexid++
              ) {
                let texttestsplit;
                let checkyear;
                let checkmonth;
                let checkday;
                console.log("this.detailstemplate.aspect_data[indexid].aspect_type", this.detailstemplate.aspect_data[indexid].aspect_type);
                if (this.detailstemplate.aspect_data[indexid].aspect_type === "DATE") {
                  checkyear = listsplitstructrue[index].split(
                    "{{" +
                      this.detailstemplate.aspect_data[indexid].aspect_name +
                      ".year" +
                      "}}"
                  );
                  checkmonth = listsplitstructrue[index].split(
                    "{{" +
                      this.detailstemplate.aspect_data[indexid].aspect_name +
                      ".month" +
                      "}}"
                  );
                  checkday = listsplitstructrue[index].split(
                    "{{" +
                      this.detailstemplate.aspect_data[indexid].aspect_name +
                      ".day" +
                      "}}"
                  );
                  console.log("checkyear", checkyear);
                  console.log("checkmonthr", checkmonth);
                  console.log("checkday", checkday);
                  if (checkyear.length > 1) {
                    texttestsplit = checkyear;
                  } else if (checkmonth.length > 1) {
                    texttestsplit = checkmonth;
                  } else if (checkday.length > 1) {
                    texttestsplit = checkday;
                  } else {
                    texttestsplit = listsplitstructrue[index].split(
                      "{{" +
                        this.detailstemplate.aspect_data[indexid].aspect_name +
                        "}}"
                    );
                  }
                } else {
                  texttestsplit = listsplitstructrue[index].split(
                    "{{" +
                      this.detailstemplate.aspect_data[indexid].aspect_name +
                      "}}"
                  );
                }
                console.log(
                  "this.detailstemplate.aspect_data",
                  this.detailstemplate.aspect_data[indexid]
                );
                console.log("texttestsplit startt", texttestsplit);
                if (texttestsplit.length > 1) {
                  if (
                    this.detailstemplate.aspect_data[indexid].aspect_null ===
                    "Y"
                  ) {
                    if (
                      this.detailstemplate.aspect_data[indexid].fixvalue === ""
                    ) {
                      console.log("this.detailstemplate.aspect_data[indexid].aspect_type fixxx", this.detailstemplate.aspect_data[indexid].aspect_type);
                      if (this.detailstemplate.aspect_data[indexid].aspect_type === "DATE") {
                        if (texttest === "") {
                          texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + "}}", this.detailstemplate.aspect_data[indexid].aspect_label)
                          texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.detailstemplate.aspect_data[indexid].aspect_label)
                          texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.detailstemplate.aspect_data[indexid].aspect_label)
                          texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.detailstemplate.aspect_data[indexid].aspect_label)
                        } else {
                          texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + "}}", this.detailstemplate.aspect_data[indexid].aspect_label)
                          texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.detailstemplate.aspect_data[indexid].aspect_label)
                          texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.detailstemplate.aspect_data[indexid].aspect_label)
                          texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.detailstemplate.aspect_data[indexid].aspect_label)
                        }
                      } else {
                        texttest === ""
                          ? (texttest = listsplitstructrue[index].replace(
                              "{{" +
                                this.detailstemplate.aspect_data[indexid]
                                  .aspect_name +
                                "}}",
                              this.detailstemplate.aspect_data[indexid]
                                .aspect_label
                            ))
                          : (texttest = texttest.replace(
                              "{{" +
                                this.detailstemplate.aspect_data[indexid]
                                  .aspect_name +
                                "}}",
                              this.detailstemplate.aspect_data[indexid]
                                .aspect_label
                            ));
                      }
                      console.log("texttest 1", texttest);
                    } else {
                      if (
                        this.detailstemplate.aspect_data[indexid]
                          .aspect_fix_value.length > 0
                      ) {
                        if (this.detailstemplate.aspect_data[indexid].aspect_type === "DATE") {
                          if (texttest === "") {
                            texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + "}}", this.changeDateFormat(this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid]))
                          } else {
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + "}}", this.changeDateFormat(this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid]))
                          }
                        } else {
                          texttest === ""
                            ? (texttest = listsplitstructrue[index].replace(
                                "{{" +
                                  this.detailstemplate.aspect_data[indexid]
                                    .aspect_name +
                                  "}}",
                                this.detailstemplate.aspect_data[indexid].value
                              ))
                            : (texttest = texttest.replace(
                                "{{" +
                                  this.detailstemplate.aspect_data[indexid]
                                    .aspect_name +
                                  "}}",
                                this.detailstemplate.aspect_data[indexid].value
                              ));
                          console.log("texttest 2", texttest);
                        }
                      } else {
                        if (texttest === "") {
                          if (this.detailstemplate.aspect_data[indexid].aspect_type === "DATE") {
                            texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + "}}", this.changeDateFormat(this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("month",this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("day",this.detailstemplate.aspect_data[indexid]))
                          } else {
                            console.log("fefef");
                            texttest = listsplitstructrue[index].replace(
                              "{{" +
                                this.detailstemplate.aspect_data[indexid]
                                  .aspect_name +
                                "}}",
                              this.detailstemplate.aspect_data[indexid]
                                .fixvalue
                            )
                          }
                        } else {
                          if (this.detailstemplate.aspect_data[indexid].aspect_type === "DATE") {
                            texttest = texttest.replace(
                              "{{" +
                                this.detailstemplate.aspect_data[indexid]
                                  .aspect_name +
                                "}}",
                              this.changeDateFormat(
                                this.detailstemplate.aspect_data[indexid]
                              )
                            ),
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("month",this.detailstemplate.aspect_data[indexid]))
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("day",this.detailstemplate.aspect_data[indexid]))
                          } else {
                            console.log("fefef");
                            texttest = texttest.replace(
                              "{{" +
                                this.detailstemplate.aspect_data[indexid]
                                  .aspect_name +
                                "}}",
                              this.detailstemplate.aspect_data[indexid].fixvalue
                            )
                          }
                        }
                        // texttest === ""
                        //   ? this.detailstemplate.aspect_data[indexid]
                        //       .aspect_type === "DATE"
                        //     ? (
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + "}}", this.changeDateFormat(this.detailstemplate.aspect_data[indexid])),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid])),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("month",this.detailstemplate.aspect_data[indexid])),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("day",this.detailstemplate.aspect_data[indexid]))
                        //       )
                        //     : (texttest = listsplitstructrue[index].replace(
                        //         "{{" +
                        //           this.detailstemplate.aspect_data[indexid]
                        //             .aspect_name +
                        //           "}}",
                        //         this.detailstemplate.aspect_data[indexid]
                        //           .fixvalue
                        //       ))
                        //   : this.detailstemplate.aspect_data[indexid]
                        //       .aspect_type === "DATE"
                        //   ? (texttest = texttest.replace(
                        //       "{{" +
                        //         this.detailstemplate.aspect_data[indexid]
                        //           .aspect_name +
                        //         "}}",
                        //       this.changeDateFormat(
                        //         this.detailstemplate.aspect_data[indexid]
                        //       )
                        //     ),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid])),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("month",this.detailstemplate.aspect_data[indexid])),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("day",this.detailstemplate.aspect_data[indexid]))
                        //     )
                        //   : (texttest = texttest.replace(
                        //       "{{" +
                        //         this.detailstemplate.aspect_data[indexid]
                        //           .aspect_name +
                        //         "}}",
                        //       this.detailstemplate.aspect_data[indexid].fixvalue
                        //     ));
                        console.log("texttest 3", texttest);
                        // structrue =
                        //   structrue +
                        //   "/" +
                        //   (this.detailstemplate.aspect_data[indexid].aspect_type ===
                        //   "DATE"
                        //     ? this.changeDateFormat(
                        //         this.detailstemplate.aspect_data[indexid]
                        //       )
                        //     : this.detailstemplate.aspect_data[indexid].fixvalue);
                      }
                    }
                  } else {
                    if (
                      this.detailstemplate.aspect_data[indexid].value !== ""
                    ) {
                      if (
                        this.detailstemplate.aspect_data[indexid]
                          .aspect_fix_value.length > 0
                          // .aspect_fix_value !== "{}"
                      ) {
                        if (
                          this.detailstemplate.aspect_data[indexid].value !==
                          "notselecttemplate"
                        ) {
                          texttest === ""
                            ? (texttest = listsplitstructrue[index].replace(
                                "{{" +
                                  this.detailstemplate.aspect_data[indexid]
                                    .aspect_name +
                                  "}}",
                                this.detailstemplate.aspect_data[indexid].value
                              ))
                            : (texttest = texttest.replace(
                                "{{" +
                                  this.detailstemplate.aspect_data[indexid]
                                    .aspect_name +
                                  "}}",
                                this.detailstemplate.aspect_data[indexid].value
                              ));
                          console.log("texttest 4", texttest);
                          // structrue =
                          //   structrue +
                          //   "/" +
                          //   this.detailstemplate.aspect_data[indexid].value;
                        } else {
                          texttest === ""
                            ? (texttest = listsplitstructrue[index].replace(
                                "{{" +
                                  this.detailstemplate.aspect_data[indexid]
                                    .aspect_name +
                                  "}}",
                                "ไม่ระบุ"
                              ))
                            : (texttest = texttest.replace(
                                "{{" +
                                  this.detailstemplate.aspect_data[indexid]
                                    .aspect_name +
                                  "}}",
                                "ไม่ระบุ"
                              ));
                        }
                      } else {
                        if (texttest === "") {
                          if (this.detailstemplate.aspect_data[indexid].aspect_type === "DATE") {
                            console.log("maaaa3",  this.setDateselect("year",this.detailstemplate.aspect_data[indexid]));
                            texttest = listsplitstructrue[index].replace(
                              "{{" +
                                this.detailstemplate.aspect_data[indexid]
                                  .aspect_name +
                                "}}",
                              this.changeDateFormat(
                                this.detailstemplate.aspect_data[indexid]
                              )
                            ),
                            texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid])),
                            texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("month",this.detailstemplate.aspect_data[indexid])),
                            texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("day",this.detailstemplate.aspect_data[indexid]))
                          } else {
                            texttest = listsplitstructrue[index].replace(
                              "{{" +
                                this.detailstemplate.aspect_data[indexid]
                                  .aspect_name +
                                "}}",
                              this.detailstemplate.aspect_data[indexid].value
                            )
                          }
                        } else {
                          if (this.detailstemplate.aspect_data[indexid].aspect_type === "DATE") {
                            console.log("maaaa4",  this.setDateselect("year",this.detailstemplate.aspect_data[indexid]));
                            texttest = texttest.replace(
                            "{{" +
                              this.detailstemplate.aspect_data[indexid]
                                .aspect_name +
                              "}}",
                            this.changeDateFormat(
                              this.detailstemplate.aspect_data[indexid]
                            )
                          ),texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid])),
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("month",this.detailstemplate.aspect_data[indexid])),
                            texttest = texttest.replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("day",this.detailstemplate.aspect_data[indexid]))
                          } else {
                            texttest = texttest.replace(
                              "{{" +
                                this.detailstemplate.aspect_data[indexid]
                                  .aspect_name +
                                "}}",
                              this.detailstemplate.aspect_data[indexid].value
                            )
                          }
                        }
                        // texttest === ""
                        //   ? this.detailstemplate.aspect_data[indexid]
                        //       .aspect_type === "DATE"
                        //     ? (texttest = listsplitstructrue[index].replace(
                        //         "{{" +
                        //           this.detailstemplate.aspect_data[indexid]
                        //             .aspect_name +
                        //           "}}",
                        //         this.changeDateFormat(
                        //           this.detailstemplate.aspect_data[indexid]
                        //         )
                        //       ),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid])),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("month",this.detailstemplate.aspect_data[indexid])),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("day",this.detailstemplate.aspect_data[indexid]))
                        //       )
                        //     : (texttest = listsplitstructrue[index].replace(
                        //         "{{" +
                        //           this.detailstemplate.aspect_data[indexid]
                        //             .aspect_name +
                        //           "}}",
                        //         this.detailstemplate.aspect_data[indexid].value
                        //       ))
                        //   : this.detailstemplate.aspect_data[indexid]
                        //       .aspect_type === "DATE"
                        //   ? (texttest = texttest.replace(
                        //       "{{" +
                        //         this.detailstemplate.aspect_data[indexid]
                        //           .aspect_name +
                        //         "}}",
                        //       this.changeDateFormat(
                        //         this.detailstemplate.aspect_data[indexid]
                        //       )
                        //     ),texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".year" + "}}", this.setDateselect("year",this.detailstemplate.aspect_data[indexid])),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".month" + "}}", this.setDateselect("month",this.detailstemplate.aspect_data[indexid])),
                        //       texttest = listsplitstructrue[index].replace("{{" + this.detailstemplate.aspect_data[indexid].aspect_name + ".day" + "}}", this.setDateselect("day",this.detailstemplate.aspect_data[indexid]))
                        //     )
                        //   : (texttest = texttest.replace(
                        //       "{{" +
                        //         this.detailstemplate.aspect_data[indexid]
                        //           .aspect_name +
                        //         "}}",
                        //       this.detailstemplate.aspect_data[indexid].value
                        //     ));
                        console.log("texttest 5", texttest);
                        // structrue =
                        //   structrue +
                        //   "/" +
                        //   (this.detailstemplate.aspect_data[indexid].aspect_type ===
                        //   "DATE"
                        //     ? this.changeDateFormat(
                        //         this.detailstemplate.aspect_data[indexid]
                        //       )
                        //     : this.detailstemplate.aspect_data[indexid].value);
                      }
                    } else {
                      texttest === ""
                        ? (texttest = listsplitstructrue[index].replace(
                            "{{" +
                              this.detailstemplate.aspect_data[indexid]
                                .aspect_name +
                              "}}",
                            "ไม่ระบุ"
                          ))
                        : (texttest = texttest.replace(
                            "{{" +
                              this.detailstemplate.aspect_data[indexid]
                                .aspect_name +
                              "}}",
                            "ไม่ระบุ"
                          ));
                    }
                  }
                }
                console.log("texttest", texttest);
                if (indexid + 1 === this.detailstemplate.aspect_data.length) {
                  if (texttest === "") {
                    if (structrue === "") {
                      structrue += listsplitstructrue[index];
                    } else {
                      structrue += "/" + listsplitstructrue[index];
                    }
                  } else {
                    if (structrue === "") {
                      structrue += texttest;
                    } else {
                      structrue += "/" + texttest;
                    }
                  }
                }
              }
              // }
            // }
            // }
            // structrue += listsplitstructrue[index];
            // countduptemplatename -= 1;
            // if (countduptemplatename === 0) {
            //   break;
            // } else {
            //   structrue += "/";
            // }
          }
          console.log("structrue", structrue);
          if (this.listaspectsymbol.length > 0) {
            return structrue + "/XXXXXXXXXX";
          } else {
            return structrue;
          }
          // return this.detailstemplate.structure_data;
          // let listsplitstructrue = !this.detailstemplate.structure_data
          //   ? ""
          //   : this.detailstemplate.structure_data.split("/");
          // let structrue = "";
          // let countduptemplatename = listsplitstructrue.filter(
          //   (item) => item === this.detailstemplate.template_name
          // ).length;
          // for (let index = 0; index < listsplitstructrue.length; index++) {
          //   if (
          //     listsplitstructrue[index] === this.detailstemplate.template_name
          //   ) {
          //     structrue += listsplitstructrue[index];
          //     countduptemplatename -= 1;
          //     if (countduptemplatename === 0) {
          //       break;
          //     } else {
          //       structrue += "/";
          //     }
          //   }
          // }
          // for (let i = 0; i < listsplitstructrue.length; i++) {
          //   if (this.detailstemplate.aspect_data[i]) {
          //     if (this.detailstemplate.aspect_data[i].aspect_null === "Y") {
          //       if (this.detailstemplate.aspect_data[i].fixvalue === "") {
          //         structrue =
          //           structrue +
          //           "/" +
          //           this.detailstemplate.aspect_data[i].aspect_label;
          //       } else {
          //         if (
          //           this.detailstemplate.aspect_data[i].aspect_fix_value
          //             .length > 0
          //         ) {
          //           structrue =
          //             structrue +
          //             "/" +
          //             this.detailstemplate.aspect_data[i].value;
          //         } else {
          //           structrue =
          //             structrue +
          //             "/" +
          //             (this.detailstemplate.aspect_data[i].aspect_type ===
          //             "DATE"
          //               ? this.changeDateFormat(
          //                   this.detailstemplate.aspect_data[i]
          //                 )
          //               : this.detailstemplate.aspect_data[i].fixvalue);
          //         }
          //       }
          //     } else {
          //       if (this.detailstemplate.aspect_data[i].value !== "") {
          //         if (
          //           this.detailstemplate.aspect_data[i].aspect_fix_value
          //             .length > 0
          //         ) {
          //           if (
          //             this.detailstemplate.aspect_data[i].value !==
          //             "notselecttemplate"
          //           ) {
          //             structrue =
          //               structrue +
          //               "/" +
          //               this.detailstemplate.aspect_data[i].value;
          //           }
          //         } else {
          //           structrue =
          //             structrue +
          //             "/" +
          //             (this.detailstemplate.aspect_data[i].aspect_type ===
          //             "DATE"
          //               ? this.changeDateFormat(
          //                   this.detailstemplate.aspect_data[i]
          //                 )
          //               : this.detailstemplate.aspect_data[i].value);
          //         }
          //       }
          //     }
          //   }
          // }
          // if (this.checktemplatefolder === true) {
          //   if (this.datafoldertemplate.path === undefined) {
          //     return structrue;
          //   } else {
          //     return this.datafoldertemplate.path + structrue;
          //   }
          // } else {
          //   return structrue;
          // }
        }
      } catch (err) {
        console.log("Still fine", err);
      }
    },
    structure_symbol_data() {
      if (this.detailstemplate) {
        if (this.checktemplatefolder === true) {
          if (this.datafoldertemplate.path === undefined) {
            // return this.detailstemplate.template_name + "/XXXXXXXXXX";
            return "XXXXXXXXXX";
          } else {
            // return this.datafoldertemplate.path + this.detailstemplate.template_name + "/XXXXXXXXXX";
            return this.datafoldertemplate.path + "XXXXXXXXXX";
          }
        } else {
          // return this.detailstemplate.template_name + "/XXXXXXXXXX";
          return "XXXXXXXXXX";
        }
      }
    },
    length_processing() {
      return (
        this.temparraydataupload.filter((item) => {
          return (
            item.status === "fail" ||
            item.status === "success" ||
            item.status === "duplicate" ||
            item.status === "skip" ||
            item.status === "cancel"
          );
        }).length ||
        this.arraydataupload.filter((item) => {
          return (
            item.status === "fail" ||
            item.status === "success" ||
            item.status === "duplicate" ||
            item.status === "skip" ||
            item.status === "cancel"
          );
        }).length ||
        0
      );
    },
    length_fail() {
      return (
        this.temparraydataupload.filter((item) => {
          return (
            item.status === "fail" ||
            item.status === "skip" ||
            item.status === "cancel"
          );
        }).length ||
        this.arraydataupload.filter((item) => {
          return (
            item.status === "fail" ||
            item.status === "skip" ||
            item.status === "cancel"
          );
        }).length ||
        0
      );
    },
    length_success() {
      return (
        this.temparraydataupload.filter((item) => {
          return item.status === "success";
        }).length ||
        this.arraydataupload.filter((item) => {
          return item.status === "success";
        }).length ||
        0
      );
    },
    length_duplicate() {
      return (
        this.temparraydataupload.filter((item) => {
          return item.status === "duplicate";
        }).length ||
        this.arraydataupload.filter((item) => {
          return item.status === "duplicate";
        }).length ||
        0
      );
    },
    length_uploading() {
      return (
        this.temparraydataupload.filter((item) => {
          return item.status === "start";
        }).length ||
        this.arraydataupload.filter((item) => {
          return item.status === "start";
        }).length ||
        0
      );
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    //style อักษรของ text ใหญ่ ใน upload layout ของ mobile
    titleTextUploadTitle() {
      if (this.resolutionScreen >= 500) {
        return "color:black; font-size: 18px; line-height: 24px;";
      } else {
        return "color:black; font-size: 14px; line-height: 24px;";
      }
    },
    //style อักษรของ text เล็ก ใน upload layout ของ mobile
    titleTextUploadSubtitle() {
      if (this.resolutionScreen >= 500) {
        return "color:red; font-size: 16px; line-height: 24px;";
      } else {
        return "color:red; font-size: 12px; line-height: 24px;";
      }
    },
    //style upload layout ของ mobile
    uploadLayoutMobile() {
      // return "background: #F0F6FB; border: 4px dashed #71B5D8; border-radius: 24px;";
      return (
        "background: " +
        this.color.BG +
        "; border: 4px dashed " +
        this.color.theme +
        "; border-radius: 24px; cursor: pointer;"
      );
    },
    uploadLayout() {
      if (this.createprogress === true) {
        return "border-radius: 24px; cursor: not-allowed;";
      } else {
        return "border-radius: 24px; cursor: pointer;";
      }
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    maxHeightOnMobile() {
      if (this.statusuploading === false) {
        return "700px";
      } else {
        return "400px";
      }
    },
    layouthoveruploadfiles() {
      if (this.overdragfile === false) {
        return "background: none; border-radius: 25px; transition: background 150ms, opacity 150ms, height 175ms, width 175ms, border 300ms; opacity: 1; position: realtive;";
      } else {
        return "background: #aeb3b5; border-radius: 25px; transition: background 350ms, opacity 350ms, height 175ms, width 175ms, border 300ms; opacity: 0.4; position: realtive;";
      }
    },
    dropzoneuploadfiles() {
      if (this.overdragfile === false) {
        return (
          "border: 4px dashed " +
          this.color.theme +
          "; font: bold 30px Sarabun, system-ui, Tahoma, sans-serif; background: " +
          this.color.BG +
          "; transition: background 200ms, opacity 200ms, height 175ms, width 175ms, border 300ms;"
        );
      } else {
        return (
          "border: 4px dashed " +
          this.color.theme +
          "; font: bold 42px Sarabun, system-ui, Tahoma, sans-serif; background: #aeb3b5; transition: background 200ms, opacity 200ms, height 175ms, width 175ms, border 300ms;"
        );
      }
    },
  },
  // filters: {
  //   subStr: function(string) {
  //     if (string == null || string == "" || string == undefined) {
  //       return string;
  //     } else {
  //       if (string.length > 15) return string.substring(0, 15) + "...";
  //       else return string;
  //     }
  //   },
  // },
  methods: {
    setDateselect(status, item) {
      console.log("status", status);
      console.log("item", item);
      let rawdate = item.showdate.split("-").join("")
      if (item.showdate) {
        if (status === "day") {
          return rawdate.substring(6, 8);
        } else if (status === "month") {
          return rawdate.substring(4, 6);
        } else if (status === "year") {
          return rawdate.substring(0, 4);
        } else {
          let value = item.showdate.split("-").join("");
          return value;
        }
      } else {
        return item.aspect_label;
      }
    },
    fn_check_smup_specific() {
      try {
        if (
          this.listbizsymbolsmartupload.includes(
            this.dataAccountActive.business_info.taxid
          )
        ) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    addFolderTemplate(data) {
      this.datafoldertemplate = data;
    },
    preparingNewFolder(item) {
      this.datafoldercreate = item;
    },
    preparingCreateFolder(id) {
      this.foldercreateid = id;
      this.opencreatefoldertemplate = true;
    },
    changeDateFormat(item) {
      if (item.showdate) {
        let value = item.showdate.split("-").join("");
        return value;
      } else {
        return "";
      }
    },
    async getDataTemplate() {
      console.log(
        "dataAccountActive",
        this.dataAccountActive.business_info.business_id
      );
      console.log("dataAccountActive", this.dataAccountId);
      let auth = await gbfGenerate.generateToken();
      let payload = {
        business_id: this.dataAccountActive.business_info.business_id,
        account_id: this.dataAccountId,
      };
      this.axios({
        method: "POST",
        url:
          process.env.VUE_APP_SERVICE_UPLOAD_API +
          "/api/v2/smart_box/get_template",
        data: payload,
        headers: { Authorization: auth.code },
      })
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response.data.data", response.data.data);
            this.datatemplate = response.data.data.filter((itemSearch) => itemSearch.status_search === 'N');
            if (response.data.data.length === 0) {
              this.detailstemplate = {
                aspect_data: [],
              };
              if (
                !this.listbizsymbolsmartupload.includes(
                  this.dataAccountActive.business_info.taxid
                )
              ) {
                this.$v.$reset();
              }
              this.loadingdatatemplate = false;
            } else {
              console.log("response.data.data", response.data.data);
              this.selectdatatemplate = this.datatemplate[0];
              console.log("this.datatemplate[0]", this.datatemplate[0]);
              this.getDetailsTemplate(
                response.data.data.filter((itemSearch) => itemSearch.status_search === 'N')[0].id,
                response.data.data.filter((itemSearch) => itemSearch.status_search === 'N')[0].structure_data,
                response.data.data.filter((itemSearch) => itemSearch.status_search === 'N')[0]
              );
            }
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("smartinput.cannotgettemplate"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("smartinput.cannotgettemplate"),
          });
        });
    },
    async getDetailsTemplate(template_id, structure_data, current_template) {
      this.loadingdatatemplate = true;
      this.currenttemplate = current_template;
      this.listaspectsymbol = [];
      let auth = await gbfGenerate.generateToken();
      let payload = {
        template_id: template_id,
      };
      this.axios({
        method: "POST",
        url:
          process.env.VUE_APP_SERVICE_UPLOAD_API +
          "/api/v2/smart_box/get_template_details",
        data: payload,
        headers: { Authorization: auth.code },
      })
        .then((response) => {
          if (response.data.status === "OK") {
            // let checkfoldertemplate = structure_data.split(
            //   "/"
            // );
            // let count_checkdupname = checkfoldertemplate.filter(
            //   (item) => item === response.data.data.template_name
            // ).length;
            // console.log("checkdupname", count_checkdupname);
            // if (checkfoldertemplate[0] !== response.data.data.template_name) {
            //   // this.checktemplatefolder = true;
            //   let path = "";
            //   for (let i = 0; i < checkfoldertemplate.length; i++) {
            //     if (
            //       checkfoldertemplate[i] !== response.data.data.template_name
            //     ) {
            //       if (checkfoldertemplate[i] !== "") {
            //         path += checkfoldertemplate[i] + "/";
            //       }
            //     } else {
            //       break;
            //     }
            //   }
            //   this.datafoldertemplate = {
            //     path: path,
            //     folder_name: checkfoldertemplate[0],
            //   };
            // } else if (count_checkdupname > 1) {
            //   this.checktemplatefolder = true;
            //   let path = "";
            //   for (let i = 0; i < checkfoldertemplate.length; i++) {
            //     if (
            //       checkfoldertemplate[i] !== response.data.data.template_name
            //     ) {
            //       path += checkfoldertemplate[i] + "/";
            //     } else if (count_checkdupname > 1) {
            //       count_checkdupname -= 1;
            //       path += checkfoldertemplate[i] + "/";
            //     } else {
            //       break;
            //     }
            //   }
            //   this.datafoldertemplate = {
            //     path: path,
            //     folder_name:
            //       checkfoldertemplate[checkfoldertemplate.length - 2],
            //   };
            // } else {
            //   this.datafoldertemplate = {};
            //   this.checktemplatefolder = false;
            // }

            // if (
            //   this.listbizsymbolsmartupload.includes(
            //     this.dataAccountActive.business_info.taxid
            //   )
            // ) {
            //   console.log("response.data", response.data);
            //   this.loadingdatatemplate = false;
            //   this.checkbizsymbolsmartupload = true;
            //   this.detailstemplate = response.data.data;
            // } else {
            // if (response.data.data.aspect_data.length === 0) {
            console.log("response.data.data.length", response.data.data.length);
            if (response.data.data.length === 0) {
              this.loadingdatatemplate = false;
              this.detailstemplate = {
                aspect_data: response.data.data,
                structure_data: structure_data,
              };
            } else {
              let listdetailvalue = [];
              for (let i = 0; i < response.data.data.length; i++) {
                response.data.data[i].showdate = "";
                response.data.data[i].menudate = false;
                response.data.data[i].erroraspectmessages = "";
                if (response.data.data[i].aspect_fix_value.length > 0) {
                  // if (response.data.data[i].aspect_fix_value !== "{}") {
                  // if (response.data.data[i].aspect_type === "") {
                  //   this.listaspectsymbol.push(response.data.data[i]);
                  // } else
                  if (response.data.data[i].aspect_null !== "Y") {
                    // let str = response.data.data[i].aspect_fix_value;
                    // str = str.substring(1, str.length - 1);
                    // let array = str.split(",");
                    // console.log("str", str);
                    // console.log("str", array);
                    // array = JSON.parse(str)
                    response.data.data[i].aspect_fix_value.push(
                      "notselecttemplate"
                    );
                    // response.data.data[i].aspect_fix_value === "{}"
                    //   ? []
                    //   : array.push("notselecttemplate");
                    response.data.data[i].value =
                      response.data.data[i].aspect_fix_value[
                        response.data.data[i].aspect_fix_value
                          .length - 1
                      ];
                      // array[array.length - 1];
                    // response.data.data[i].aspect_fix_value = array;
                    console.log(
                      "response.data.data[i].value",
                      response.data.data[i].aspect_fix_value
                    );
                    response.data.data[i].fixvalue = "notuse";
                    listdetailvalue.push(response.data.data[i]);
                    console.log(
                      " response.data.data[i].value vdvdv",
                      listdetailvalue
                    );
                  } else {
                    // let str = response.data.data[i].aspect_fix_value;
                    // str = str.substring(1, str.length - 1);
                    // let array = str.split(",");
                    // console.log("str", str);
                    // console.log("str", array);
                    // response.data.data[i].value = array[0];
                    response.data.data[i].fixvalue = "notuse";
                    response.data.data[i].value = response.data.data[i].aspect_fix_value[0];
                    // response.data.data[i].aspect_fix_value = array;
                    listdetailvalue.push(response.data.data[i]);
                    console.log(
                      " response.data.data[i].value vdvdv",
                      listdetailvalue
                    );
                  }
                  let check_structure_file_name = current_template.structure_file_name.search("{{" + response.data.data[i].aspect_name + "}}")
                  if (check_structure_file_name !== -1) {
                    response.data.data[i].readfilename = "Y"
                  } else {
                    response.data.data[i].readfilename = "N"
                  }

                  // if (check_structure_file_name !== -1) {
                  //   this.listaspectsymbol.concat(array)
                  // }
                } else {
                  let check_structure_file_name = current_template.structure_file_name.search("{{" + response.data.data[i].aspect_name + "}}")
                  if (check_structure_file_name !== -1) {
                    response.data.data[i].readfilename = "Y"
                  } else {
                    response.data.data[i].readfilename = "N"
                  }
                  if (response.data.data[i].aspect_null === "Y") {
                    response.data.data[i].fixvalue = "";
                    if (response.data.data[i].aspect_default !== "") {
                      response.data.data[i].fixvalue = response.data.data[i].aspect_default;
                    } else {
                      response.data.data[i].fixvalue = "";
                      if (response.data.data[i].aspect_type === "DATE") {
                        let datenow = format(new Date(), "yyyy-MM-dd");
                        let datenownoformat = format(new Date(), "yyyyMMdd");
                        response.data.data[i].fixvalue = datenownoformat;
                        response.data.data[i].showdate = datenow;
                      }
                    }
                  } else {
                    if (response.data.data[i].aspect_default !== "") {
                      response.data.data[i].value = response.data.data[i].aspect_default;
                    } else {
                      response.data.data[i].value = "";
                      // if (response.data.data[i].aspect_type === "DATE") {
                      //   let datenow = format(new Date(), "yyyy-MM-dd");
                      //   let datenownoformat = format(new Date(), "yyyyMMdd");
                      //   response.data.data[i].value = datenownoformat;
                      //   response.data.data[i].showdate = datenow;
                      // }
                    }
                    response.data.data[i].fixvalue = "notuse";
                  }
                  listdetailvalue.push(response.data.data[i]);
                }
                console.log("response.data.data", response.data.data.length);
                if (i + 1 === response.data.data.length) {
                  console.log("this.listaspectsymbol", this.listaspectsymbol);
                  this.$v.$reset();
                  this.detailstemplate = {
                    aspect_data: listdetailvalue,
                    structure_data: structure_data,
                  };
                  this.loadingdatatemplate = false;
                }
              }
            }
            // }
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("smartinput.cannotgetdetailtemplate"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("smartinput.cannotgetdetailtemplate"),
          });
        });
    },
    // ลูปการเก็บไฟล์ของการเลือกไฟล์
    async fnStackFile(e) {
      this.temparraydataupload = [];
      this.statusuploading = false;
      this.fileImport = e.target.files;
      let i = this.totalstackfile;
      let totalallfile = this.totalstackfile + this.fileImport.length;
      let numcreateformdata = 0;
      this.stackfileloading = true;
      for (i; i < totalallfile; i++) {
        let formData = await this.createPayloadUpload(i, numcreateformdata);
        this.arraydataupload[i].formData = formData;
        this.totalstackfile = this.totalstackfile + 1;
        numcreateformdata = numcreateformdata + 1;
        if (totalallfile === i + 1) {
          this.stackfileloading = false;
        }
      }
    },
    // ลูปการเก็บไฟล์ของการลากไฟล์
    async fnStackFileDrug(files) {
      this.temparraydataupload = [];
      this.statusuploading = false;
      this.fileImport = files;
      let i = this.totalstackfile;
      let totalallfile = this.totalstackfile + this.fileImport.length;
      let numcreateformdata = 0;
      this.stackfileloading = true;
      for (i; i < totalallfile; i++) {
        let formData = await this.createPayloadUpload(i, numcreateformdata);
        this.arraydataupload[i].formData = formData;
        this.totalstackfile = this.totalstackfile + 1;
        numcreateformdata = numcreateformdata + 1;
        if (totalallfile === i + 1) {
          this.stackfileloading = false;
        }
      }
    },
    // ลบไฟล์ที่เก็บ
    fnDeleteStackFile(filedata) {
      var index = this.arraydataupload.indexOf(filedata);
      this.arraydataupload.splice(index, 1);
      this.totalstackfile = this.totalstackfile - 1;
      let listitemduplicate = this.arraydataupload.filter(
        (item) => item.name === filedata.name
      );
      if (listitemduplicate.length > 0) {
        if (listitemduplicate[0].errorduplicate === "nonapi") {
          let indexduplicate = this.arraydataupload.indexOf(
            listitemduplicate[0]
          );
          this.arraydataupload[indexduplicate].errorduplicate = "not";
          this.arraydataupload[indexduplicate]["status"] = "start";
          this.arraydataupload[indexduplicate]["msg"] = "";
          this.arraydataupload[indexduplicate]["colorupload"] = "primary";
          this.arraydataupload[indexduplicate]["value"] = 0;
        }
      }
    },
    // ดักการรีโหลด
    forceDestroyEvent(status) {
      if (this.openalertduplicatefile === false) {
        if (status === true) {
          window.addEventListener("beforeunload", this.removeUser, true);
        } else {
          window.removeEventListener("beforeunload", this.removeUser, true);
        }
      }
    },
    // event
    removeUser(event) {
      event.returnValue = "Uploading folder";
    },
    // คำนวณขนาดไฟล์
    calculatesize(_sizefilebyte) {
      sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    },
    // ลบ event
    removeEventUploadfiles() {
      var self = this;
      console.log("removeEventUploadfiles");
      self.closeDragDrop = true;
      window.removeEventListener("dragenter", self.dragenteruploadfiles, true);
      window.removeEventListener("dragleave", self.dragleaveuploadfiles, true);
      window.removeEventListener("dragover", self.dragoveruploadfiles, true);
      window.removeEventListener("drop", self.dropuploadfiles, true);
      window
        .matchMedia("(orientation: portrait)")
        .removeListener(this.fnOrientation);
    },
    // เพิ่ม event dragdrop
    addEventUploadfiles() {
      console.log("addEventUploadfiles");
      if (this.resolutionScreen >= 500) {
        // ######### เช็คว่าหน้าจอเล็กไม่ให้ drag drop ได้ #########
        var self = this;
        self.closeDragDrop = false;
        window.addEventListener("dragenter", self.dragenteruploadfiles, true);
        window.addEventListener("dragleave", self.dragleaveuploadfiles, true);
        window.addEventListener("dragover", self.dragoveruploadfiles, true);
        window.addEventListener("drop", self.dropuploadfiles, true);
      }
    },
    // ลากเข้าไปโซน
    dragenteruploadfiles(e) {
      // console.log("dragenter dropzoneuploadfiles", e);
      if (
        e.target.id === "inputuploadfiles" ||
        e.target.id === "layouthoveruploadfiles" ||
        e.target.id === "dropzoneuploadfiles" ||
        e.target.id === "titleTextUploadtitle" ||
        e.target.id === "titleTextUploadsubtitle" ||
        e.target.id === "listStackFiles" ||
        e.target.className === "v-responsive__content"
      ) {
        if (this.createprogress === false) {
          this.overdragfile = true;
        }
      }
    },
    // ลากเข้าเช็คทุกๆ 200 milliseconds
    dragoveruploadfiles(e) {
      // console.log("dragover dropzoneuploadfiles", e);
      if (
        e.target.id === "inputuploadfiles" ||
        e.target.id === "layouthoveruploadfiles" ||
        e.target.id === "dropzoneuploadfiles" ||
        e.target.id === "titleTextUploadtitle" ||
        e.target.id === "titleTextUploadsubtitle" ||
        e.target.id === "listStackFiles" ||
        e.target.className === "v-responsive__content"
      ) {
        if (this.createprogress === false) {
          e.preventDefault();
          e.stopPropagation();
          // document.querySelector("#dropzoneuploadfiles").style.border = "4px dashed " + this.color.theme;
          // document.querySelector("#dropzoneuploadfiles").style.background =
          //   "#aeb3b5";
          // document.querySelector("#layouthoveruploadfiles").style.background =
          //   "#aeb3b5";
          // document.querySelector("#layouthoveruploadfiles").style.opacity = "0.4";
          this.overdragfile = true;
        }
      }
    },
    // ลากออก
    dragleaveuploadfiles(e) {
      // console.log("dragleave dropzoneuploadfiles", e);
      if (this.createprogress === false) {
        e.preventDefault();
        // e.stopPropagation();
        // document.querySelector("#dropzoneuploadfiles").style.border = "4px dashed " + this.color.theme;
        // document.querySelector("#dropzoneuploadfiles").style.background = this.color.BG;
        // document.querySelector("#layouthoveruploadfiles").style.background = this.color.BG;
        // document.querySelector("#layouthoveruploadfiles").style.opacity = "1";
        this.overdragfile = false;
      }
    },
    // ลากแล้วปล่อยไฟล์
    async dropuploadfiles(e) {
      // console.log("drop dropzoneuploadfiles", e);
      if (
        e.target.id === "inputuploadfiles" ||
        e.target.id === "layouthoveruploadfiles" ||
        e.target.id === "dropzoneuploadfiles" ||
        e.target.id === "titleTextUploadtitle" ||
        e.target.id === "titleTextUploadsubtitle" ||
        e.target.id === "listStackFiles" ||
        e.target.className === "v-responsive__content"
      ) {
        if (this.createprogress === false) {
          e.preventDefault();
          e.stopPropagation();
          // document.querySelector("#dropzoneuploadfiles").style.border =
          //   "4px dashed #5fb588";
          // document.querySelector("#dropzoneuploadfiles").style.background =
          //   "#e8eff2";
          // document.querySelector("#layouthoveruploadfiles").style.background =
          //   "#e8eff2";
          // document.querySelector("#layouthoveruploadfiles").style.opacity = "1";
          let items = e.dataTransfer;
          this.overdragfile = false;
          this.fnStackFileDrug(items.files);
        }
      }
    },
    // เด้งให้เลือกไฟล์
    ImportFiles() {
      if (this.createprogress === false) {
        this.selectingImportFiles = true;
        this.fileImport = null;
        this.$refs.importdoc.value = null;
        window.addEventListener(
          "focus",
          () => {
            this.selectingImportFiles = false;
          },
          { once: true }
        );
        this.$refs.importdoc.click();
      }
    },
    // ทำงานตาม action ที่ผู้ใช้เลือกเมื่อไฟล์ซ้ำ (สำหรับโทรศัพท์)
    setStatusFileDuplicate(checkaction, id) {
      let i = this.temparraydataupload.findIndex((item) => item.id === id);
      if (checkaction !== "") {
        // แทนที่
        if (checkaction === "replace") {
          this.temparraydataupload[i].fileduplicate = "create_tag_version";
        }
        // คัดลอก
        else if (checkaction === "copy") {
          this.temparraydataupload[i].fileduplicate = "create_copy";
        }
        this.reloadId = id;
        this.fnLoopUploadFile();
      } else {
        this.forceDestroyEvent(false);
        // console.log("test skip");
        this.temparraydataupload[i].status = "skip";
        this.temparraydataupload[i].msg = this.$t("fileduplicate.skipmsg");
        this.temparraydataupload[i].colorupload = "error";
        this.temparraydataupload[i].value = 100;
        if (this.length_processing === this.temparraydataupload.length) {
          this.createprogress = false;
        }
      }
    },
    // สร้าง payload สำหรับอัปโหลด
    async createPayloadUpload(i, numcreateformdata) {
      try {
        if (this.temparraydataupload.length !== 0) {
          // set data
          const formDataReload = new FormData();
          formDataReload.append("user_id", this.dataUsername);
          formDataReload.append("account_id", this.dataAccountId);
          formDataReload.append(
            "business_id",
            this.dataAccountActive.business_info.business_id
          );
          // if (
          //   this.listbizsymbolsmartupload.includes(
          //     this.dataAccountActive.business_info.taxid
          //   )
          // ) {
          //   let arraystructuresymboldata = this.structure_symbol_data.split(
          //     "XXXXXXXXXX"
          //   );
          //   // "/XXXXXXXXXX"
          //   formDataReload.append(
          //     "structure_data",
          //     arraystructuresymboldata[0].slice(
          //       0,
          //       arraystructuresymboldata[0].length - 1
          //     )
          //   );
          //   formDataReload.append(
          //     "template_id",
          //     this.selectdatatemplate.template_id
          //   );
          // } else {
          // formDataReload.append("structure_data", this.structure_data);
          // }
          formDataReload.append("file", this.temparraydataupload[i].file);
          formDataReload.append(
            "filename",
            this.temparraydataupload[i].name +
              "." +
              this.temparraydataupload[i].typefile
          );
          return formDataReload;
        } else {
          let typefile;
          let filename;
          if (!this.arraydataupload[i]) {
            typefile = this.fileImport[numcreateformdata].name.split(".");
            typefile = typefile[typefile.length - 1];
            console.log("typefile", typefile);
            filename = this.fileImport[numcreateformdata].name
              .split(".")
              .slice(0, -1);
            let iconfile = setIconFiles.fn_seticon(typefile);
            // ปั้นดาต้าใหม่
            let datafileupload = {};
            datafileupload["file"] = this.fileImport[numcreateformdata];
            datafileupload["icon"] = iconfile;
            datafileupload["name"] = filename;
            datafileupload["size"] = this.fileImport[numcreateformdata].size;
            datafileupload["canceltoken"] = null;
            datafileupload["id"] = this.uniqueidfile;
            datafileupload["typefile"] = typefile;
            let fileName = this.arraydataupload.find(
              (element) => element.name === datafileupload["name"]
            );
            if (fileName !== undefined) {
              if (fileName.errorduplicate === "api") {
                datafileupload["errorduplicate"] = fileName.errorduplicate;
              } else {
                datafileupload["errorduplicate"] = "nonapi";
              }
              datafileupload["status"] = "duplicate";
              datafileupload["msg"] = this.$t("uploadfile.fileduplicate");
              datafileupload["colorupload"] = "warning";
              datafileupload["value"] = 99;
              datafileupload["checkfailformat"] = false;
            } else {
              datafileupload["errorduplicate"] = "not";
              datafileupload["status"] = "start";
              datafileupload["msg"] = "";
              datafileupload["colorupload"] = "primary";
              datafileupload["checkfailformat"] = false;
              datafileupload["value"] = 0;
            }
            this.arraydataupload.push(datafileupload);
            this.uniqueidfile += 1;
          }
          // set data
          const formData = new FormData();
          // formData.append("user_id", this.dataUsername);
          // formData.append("account_id", this.dataAccountId);
          // formData.append(
          //   "business_id",
          //   this.dataAccountActive.business_info.business_id
          // );
          // formData.append("file", this.arraydataupload[i].file);
          // formData.append(
          //   "filename",
          //   this.arraydataupload[i].name +
          //     "." +
          //     this.arraydataupload[i].typefile
          // );
          return formData;
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    // ลูปสำหรับการอัปโหลดไฟล์
    async fnLoopUploadFile() {
      // if (this.checkbizsymbolsmartupload === false) {
      this.$v.$touch();
      // }
      if (this.checktemplatefolder === true) {
        console.log("feofjejf");
        if (this.datafoldertemplate.path === undefined) {
          Toast.fire({
            icon: "error",
            title: this.$t("smartinput.pleaseselectfoldertemplate"),
          });
          return;
        }
      }
      if (this.reloadId !== "") {
        this.createprogress = true;
        let i = this.temparraydataupload.findIndex(
          (item) => item.id === this.reloadId
        );
        console.log("this.reloadId", this.reloadId);
        console.log(
          "this.reloadId",
          this.temparraydataupload[i].checkfailformat
        );
        this.$v.$touch();
        this.temparraydataupload[i].status = "start";
        this.temparraydataupload[i].msg = "";
        this.temparraydataupload[i].colorupload = "primary";
        this.temparraydataupload[i].value = 0;
        this.reloadId = "";
        this.temparraydataupload[i].checkfailformat = false;
        let formDataDuplicate = await this.createPayloadUpload(i);
        this.temparraydataupload[i].formData = formDataDuplicate;
        await this.fnCheckStorage();
        let count_file = 0;
        if (count_file <= 5) {
          // if (this.temparraydataupload[i].size > 524288000) {
          //   this.fnUploadChunk(i);
          // } else {
          this.fnUploadFile(this.temparraydataupload[i].formData, i);
          // }
          count_file += 1;
        } else {
          // console.log("count_file else", count_file);
          // if (this.temparraydataupload[i].size > 524288000) {
          //   await this.fnUploadChunk(i);
          // } else {
          await this.fnUploadFile(this.temparraydataupload[i].formData, i);
          // }
          count_file = 0;
        }
      } else if (this.totalstackfile > 0) {
        // this.listbizsymbolsmartupload.includes(
        //   this.dataAccountActive.business_info.taxid
        // )
        // สลับ layout
        this.checkdetailmobile = false;
        this.totalstackfile = 0;
        this.statusuploading = true;
        this.createprogress = true;
        // เงื่อนไขการอัปโหลดจาก upload layout
        this.temparraydataupload = [...this.arraydataupload];
        this.checkhashtag = false;
        for (let i = 0; i < this.temparraydataupload.length; i++) {
          try {
            // if (this.checkbizsymbolsmartupload === true) {
            //   let arraystructuresymboldata = this.structure_symbol_data.split(
            //     "XXXXXXXXXX"
            //   );
            //   // "/XXXXXXXXXX"
            //   console.log(
            //     "arraystructuresymboldatat",
            //     arraystructuresymboldata[0]
            //   );
            //   this.temparraydataupload[i].formData.append(
            //     "structure_data",
            //     arraystructuresymboldata[0].slice(
            //       0,
            //       arraystructuresymboldata[0].length - 1
            //     )
            //   );
            //   this.temparraydataupload[i].formData.append(
            //     "template_id",
            //     this.selectdatatemplate.template_id
            //   );
            // } else {
            // this.temparraydataupload[i].formData.append(
            //   "structure_data",
            //   this.structure_data
            // );
            // }
            await this.fnCheckStorage();
            let count_file = 0;
            if (count_file <= 5) {
              // if (this.temparraydataupload[i].size > 524288000) {
              //   this.fnUploadChunk(i);
              // } else {
              this.fnUploadFile(this.temparraydataupload[i].formData, i);
              // }
              count_file += 1;
            } else {
              // console.log("count_file else", count_file);
              // if (this.temparraydataupload[i].size > 524288000) {
              //   await this.fnUploadChunk(i);
              // } else {
              await this.fnUploadFile(this.temparraydataupload[i].formData, i);
              // }
              count_file = 0;
            }
          } catch (err) {
            console.log("error", err);
            if (this.temparraydataupload[i].status !== "cancel") {
              this.temparraydataupload[i].status = "fail";
              this.temparraydataupload[i].msg = this.$t(
                "uploadfile.uploadfail"
              );
              this.temparraydataupload[i].colorupload = "error";
            } else {
              this.temparraydataupload[i].status = "cancel";
              this.temparraydataupload[i].msg = this.$t(
                "dragdrop.cancelupload"
              );
              this.temparraydataupload[i].colorupload = "error";
            }
            if (this.temparraydataupload.length === this.length_processing) {
              this.removeEventUploadfiles();
              this.addEventUploadfiles();
              this.createprogress = false;
            }
          }
        }
      } else if (
        !this.$v.$invalid &&
        this.totalstackfile > 0 &&
        !this.$v.$error
      ) {
        // สลับ layout
        this.checkdetailmobile = false;
        this.totalstackfile = 0;
        this.statusuploading = true;
        this.createprogress = true;
        // เงื่อนไขการอัปโหลดจาก upload layout
        this.temparraydataupload = [...this.arraydataupload];
        this.checkhashtag = false;
        for (let i = 0; i < this.temparraydataupload.length; i++) {
          try {
            this.temparraydataupload[i].formData.append(
              "structure_data",
              this.structure_data
            );
            await this.fnCheckStorage();
            let count_file = 0;
            if (count_file <= 5) {
              // if (this.temparraydataupload[i].size > 524288000) {
              //   this.fnUploadChunk(i);
              // } else {
              this.fnUploadFile(this.temparraydataupload[i].formData, i);
              // }
              count_file += 1;
            } else {
              // console.log("count_file else", count_file);
              // if (this.temparraydataupload[i].size > 524288000) {
              //   await this.fnUploadChunk(i);
              // } else {
              await this.fnUploadFile(this.temparraydataupload[i].formData, i);
              // }
              count_file = 0;
            }
          } catch (err) {
            console.log("error", err);
            if (this.temparraydataupload[i].status !== "cancel") {
              this.temparraydataupload[i].status = "fail";
              this.temparraydataupload[i].msg = this.$t(
                "uploadfile.uploadfail"
              );
              this.temparraydataupload[i].colorupload = "error";
            } else {
              this.temparraydataupload[i].status = "cancel";
              this.temparraydataupload[i].msg = this.$t(
                "dragdrop.cancelupload"
              );
              this.temparraydataupload[i].colorupload = "error";
            }
            if (this.temparraydataupload.length === this.length_processing) {
              this.removeEventUploadfiles();
              this.addEventUploadfiles();
              this.createprogress = false;
            }
          }
        }
      } else {
        if (this.$v.$invalid) {
          this.checkdetailmobile = true;
        }
        if (this.totalstackfile === 0) {
          Toast.fire({
            icon: "error",
            title: this.$t("smartinput.pleaseselectfile"),
          });
        }
      }
    },
    // เรียกใช้ api สำหรับอัปโหลด
    async fnUploadFile(formData, i) {
      try {
        if (this.userdata.remain_storage > this.temparraydataupload[i].size) {
          let formData = new FormData();
          formData.append("user_id", this.dataUsername);
          formData.append("account_id", this.dataAccountId);
          formData.append(
            "business_id",
            this.dataAccountActive.business_info.business_id
          );
          formData.append("file", this.temparraydataupload[i].file);
          formData.append(
            "filename",
            this.temparraydataupload[i].name +
              "." +
              this.temparraydataupload[i].typefile
          );
          if (this.listaspectsymbol.length > 0) {
            let splitstructure_data = this.structure_data.split("/XXXXXXXXXX");
            // formData.append("structure_data", splitstructure_data[0]);
          } else {
            // formData.append("structure_data", this.structure_data);
          }
          // formData.append("aspect_symbol_id", this.currenttemplate.aspect_symbol_id);
          formData.append("template_id", this.currenttemplate.id);
          for (let j = 0; j < this.detailstemplate.aspect_data.length; j++) {
            let raw_aspect_data = {
              aspect_id: this.detailstemplate.aspect_data[j].id,
              value: "ไม่ระบุ",
              aspect_name: this.detailstemplate.aspect_data[j].aspect_name,
              // status_file_name: "N"
            };
            if (
              this.detailstemplate.aspect_data[j]
              .aspect_fix_value.length > 0
              // .aspect_fix_value !== "{}"
            ) {
              if (this.detailstemplate.aspect_data[j].aspect_type === "") {
                raw_aspect_data.value = "ไม่ระบุ";
              } else if (
                this.detailstemplate.aspect_data[j].aspect_null !== "Y"
              ) {
                if (this.detailstemplate.aspect_data[
                  j
                ].value === "") {
                  raw_aspect_data.value = "ไม่ระบุ";
                } else {  
                  raw_aspect_data.value = this.detailstemplate.aspect_data[
                    j
                  ].value;
                }
              } else {
                if (this.detailstemplate.aspect_data[
                  j
                ].value === "") {
                  raw_aspect_data.value = "ไม่ระบุ";
                } else {  
                  raw_aspect_data.value = this.detailstemplate.aspect_data[
                    j
                  ].value;
                }
              }
            } else {
              if (this.detailstemplate.aspect_data[j].aspect_null === "Y") {
                raw_aspect_data.value = this.detailstemplate.aspect_data[
                  j
                ].fixvalue;
              } else {
                if (this.detailstemplate.aspect_data[
                  j
                ].value === "") {
                  raw_aspect_data.value = "ไม่ระบุ";
                } else {  
                  raw_aspect_data.value = this.detailstemplate.aspect_data[
                    j
                  ].value;
                }
              }
            }
            let aspect_data = JSON.stringify(raw_aspect_data);
            formData.append("aspect_data", aspect_data);
          }
          this.temparraydataupload[i].formData = formData;

          const CancelToken = this.axios.CancelToken;
          this.source = CancelToken.source();
          this.temparraydataupload[i].canceltoken = this.source;
          console.log(
            "this.temparraydataupload[i]",
            this.temparraydataupload[i]
          );
          let auth = await gbfGenerate.generateToken();
          let url =
            // this.checkbizsymbolsmartupload === true
            //   ? "/api/smart_box/v2/upload_files"
            //   :
            // "/api/smart_box/v1/upload_files";
            "/api/smart_box/v3/upload_files";
          this.axios({
            method: "POST",
            url: process.env.VUE_APP_SERVICE_UPLOAD_API + url,
            cancelToken: this.temparraydataupload[i].canceltoken.token,
            data: this.temparraydataupload[i].formData,
            headers: { Authorization: auth.code },
            onUploadProgress: (e) => {
              if ((e.loaded / e.total) * 100 >= 95) {
                this.temparraydataupload[i]["value"] = 95;
                this.percentfileuploading = this.temparraydataupload[i][
                  "value"
                ];
                this.percentfileuploading = this.temparraydataupload[i][
                  "value"
                ];
              } else {
                this.temparraydataupload[i]["value"] = parseInt(
                  (e.loaded / e.total) * 100
                );
                this.percentfileuploading = this.temparraydataupload[i][
                  "value"
                ];
                this.percentfileuploading = this.temparraydataupload[i][
                  "value"
                ];
              }
            },
          })
            .then((response) => {
              if (response.data.status === "OK") {
                // สถานะเคยอัปโหลดสำเร็จ
                this.checkstatusupload = true;
                this.temparraydataupload[i].status = "success";
                this.temparraydataupload[i].msg = this.$t(
                  "uploadfile.uploadsuccess"
                );
                this.temparraydataupload[i].colorupload = "success";
                this.temparraydataupload[i].value = 100;
                this.countupload += 1;
                // let payload = {
                //   data_id: response.data.data.id,
                //   data_type: "file",
                //   user_id: this.dataUsername,
                //   account_id: this.dataAccountId,
                //   aspect_data: [],
                // };
                // if (this.checkbizsymbolsmartupload === false) {
                // this.payloadInsertAspactData(payload, i);
                // } else {
                //   // สถานะเคยอัปโหลดสำเร็จ
                //   this.checkstatusupload = true;
                //   this.temparraydataupload[i].status = "success";
                //   this.temparraydataupload[i].msg = this.$t(
                //     "uploadfile.uploadsuccess"
                //   );
                //   this.temparraydataupload[i].colorupload = "success";
                //   this.temparraydataupload[i].value = 100;
                //   this.countupload += 1;
                // }
              } else if (
                response.data.errorMessage === "Value from file not same dropdown"
              ) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "smartinput.valuesamedropdown"
                );
                this.temparraydataupload[i].colorupload = "error";
                this.temparraydataupload[i].checkfailformat = true;
              } else if (
                response.data.errorMessage === "Pettern file name wrong"
              ) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "smartinput.yourfileformatwrong"
                );
                this.temparraydataupload[i].colorupload = "error";
                this.temparraydataupload[i].checkfailformat = true;
              } else if (
                response.data.errorMessage === "Your space not enough"
              ) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.yourspacenotenough"
                );
                this.temparraydataupload[i].colorupload = "error";
              } else if (
                response.data.errorMessage === "File Size More Than 5 GB"
              ) {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.morefivegb"
                );
                this.temparraydataupload[i].colorupload = "error";
              } else {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.cannotdetailupload"
                );
                this.temparraydataupload[i].colorupload = "error";
              }

              if (this.temparraydataupload.length === this.length_processing) {
                this.removeEventUploadfiles();
                this.addEventUploadfiles();
                this.createprogress = false;
              }
            })
            .catch((thrown) => {
              console.log(thrown);
              if (this.temparraydataupload[i].status !== "cancel") {
                this.temparraydataupload[i].status = "fail";
                this.temparraydataupload[i].msg = this.$t(
                  "uploadfile.uploadfail"
                );
                this.temparraydataupload[i].colorupload = "error";
              } else {
                this.temparraydataupload[i].status = "cancel";
                this.temparraydataupload[i].msg = this.$t(
                  "dragdrop.cancelupload"
                );
                this.temparraydataupload[i].colorupload = "error";
              }
              if (this.temparraydataupload.length === this.length_processing) {
                this.removeEventUploadfiles();
                this.addEventUploadfiles();
                this.createprogress = false;
              }
            });
        } else {
          this.statusstorage = true;
          this.temparraydataupload[i].status = "fail";
          this.temparraydataupload[i].msg = this.$t(
            "dragdrop.yourspacenotenough"
          );
          this.temparraydataupload[i].colorupload = "error";
          this.createprogress = false;
        }
      } catch (err) {
        console.log("error", err);
        if (this.temparraydataupload[i].status !== "cancel") {
          this.temparraydataupload[i].status = "fail";
          this.temparraydataupload[i].msg = this.$t("uploadfile.uploadfail");
          this.temparraydataupload[i].colorupload = "error";
        } else {
          this.temparraydataupload[i].status = "cancel";
          this.temparraydataupload[i].msg = this.$t("dragdrop.cancelupload");
          this.temparraydataupload[i].colorupload = "error";
        }
        if (this.temparraydataupload.length === this.length_processing) {
          this.removeEventUploadfiles();
          this.addEventUploadfiles();
          this.createprogress = false;
        }
      }
    },
    async payloadInsertAspactData(payload, index) {
      try {
        for (let i = 0; i < this.detailstemplate.aspect_data.length; i++) {
          let aspect_data = {};
          if (this.detailstemplate.aspect_data[i].status_value === "Y") {
            let value = this.changeDateFormat(
              this.detailstemplate.aspect_data[i]
            );
            if (this.detailstemplate.aspect_data[i].aspect_type === "DATE") {
              this.detailstemplate.aspect_data[i].aspect_null === "Y"
                ? (this.detailstemplate.aspect_data[i].fixvalue = value)
                : (this.detailstemplate.aspect_data[i].value = value);
            }
            aspect_data = {
              aspect_id: this.detailstemplate.aspect_data[i].aspect_id,
              value:
                this.detailstemplate.aspect_data[i].aspect_null === "Y"
                  ? this.detailstemplate.aspect_data[i].aspect_type ===
                    "DROPDOWN"
                    ? this.detailstemplate.aspect_data[i].value
                    : this.detailstemplate.aspect_data[i].fixvalue
                  : this.detailstemplate.aspect_data[i].value,
            };
          }
          payload.aspect_data.push(aspect_data);
          if (this.detailstemplate.aspect_data.length === i + 1) {
            await this.callInsertAspactData(payload, index);
          }
        }
      } catch (err) {
        console.log("error", err);
      }
    },
    async callInsertAspactData(payload, i) {
      try {
        let auth = await gbfGenerate.generateToken();
        this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/v1/smart_box/insert_aspect_data",
          data: payload,
          headers: { Authorization: auth.code },
        })
          .then((response) => {
            if (response.data.status === "OK") {
              // สถานะเคยอัปโหลดสำเร็จ
              this.checkstatusupload = true;
              this.temparraydataupload[i].status = "success";
              this.temparraydataupload[i].msg = this.$t(
                "uploadfile.uploadsuccess"
              );
              this.temparraydataupload[i].colorupload = "success";
              this.temparraydataupload[i].value = 100;
              this.countupload += 1;
            } else {
              this.temparraydataupload[i].status = "fail";
              this.temparraydataupload[i].msg = this.$t(
                "dragdrop.cannotdetailupload"
              );
              this.temparraydataupload[i].colorupload = "error";
            }

            if (this.temparraydataupload.length === this.length_processing) {
              this.removeEventUploadfiles();
              this.addEventUploadfiles();
              this.createprogress = false;
            }
          })
          .catch((thrown) => {
            console.log(thrown);
            if (this.temparraydataupload[i].status !== "cancel") {
              this.temparraydataupload[i].status = "fail";
              this.temparraydataupload[i].msg = this.$t(
                "uploadfile.uploadfail"
              );
              this.temparraydataupload[i].colorupload = "error";
            }
            if (this.temparraydataupload.length === this.length_processing) {
              this.removeEventUploadfiles();
              this.addEventUploadfiles();
              this.createprogress = false;
            }
          });
      } catch (err) {
        console.log("error", err);
      }
    },
    // เช็คพื้นที่ข้อมูล
    async fnCheckStorage() {
      try {
        let payload = {
          account_id: this.dataAccountId,
          convert_storage: "True",
        };
        let auth = await gbfGenerate.generateToken();
        let response = await this.axios.post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_BUSINESS_CHECK_STORAGE +
            "/api/check_used_storage",
          payload,
          { headers: { Authorization: auth.code } }
        );
        if (response.data.status === "OK") {
          let remain = await sizeDataConverter.convertStorageToByte(
            response.data.result[0].remain_storage
          );
          this.userdata.remain_storage = remain;
        } else {
          console.log("res", response);
        }
      } catch (err) {
        console.log("err", err);
      }
    },
    // สำหรับยกเลิกการอัปโหลดของ mobile
    cancelUpload(i) {
      this.temparraydataupload[i].status = "cancel";
      this.temparraydataupload[i].msg = this.$t("dragdrop.cancelupload");
      this.temparraydataupload[i].colorupload = "error";
      this.temparraydataupload[i].canceltoken.cancel();
    },
    // ฟังก์ชันเช็คไฟล์ซ้ำ
    async checkDuplicateData(name, folder_id) {
      let payload = {
        folder_id: folder_id,
        data_type: "file",
        data_name: name,
      };
      let auth = await gbfGenerate.generateToken();
      try {
        let response = await this.axios({
          method: "POST",
          url:
            process.env.VUE_APP_SERVICE_UPLOAD_API +
            "/api/v1/check_duplicate_data",
          data: payload,
          headers: { Authorization: auth.code },
        });
        if (response.data.status === "OK") {
          if (response.data.message === "duplicate filename") {
            return new Promise(async (resolve, reject) => {
              resolve({
                status: "duplicate",
                msg: response.data.message,
              });
            });
          } else {
            return new Promise(async (resolve, reject) => {
              resolve({
                status: "upload success",
                msg: response.data.message,
              });
            });
          }
        } else {
          return new Promise(async (resolve, reject) => {
            resolve({
              status: "upload denied",
              msg: response.data.errorMessage,
            });
          });
        }
      } catch (err) {
        return new Promise(async (resolve, reject) => {
          resolve({
            status: "upload denied",
            msg: err,
          });
        });
      }
    },
    cleardata() {
      this.statusuploading = false;
      this.createprogress = false;
      this.selectingimportFiles = false;
      this.fileImport = null;
      this.percentfileuploading = 0;
      this.arraydataupload = [];
      this.countupload = 0;
      this.checkstatusduplicate = false;
      this.totalstackfile = 0;
      this.temparraydataupload = [];
      this.stackfileloading = false;
      this.userdata = {};
      this.uniqueidfile = 0;
      this.checkdetailmobile = false;
      this.checktemplatefolder = false;
      this.datafoldertemplate = {};
      this.listaspectsymbol = [];
      try {
        // if (
        //   this.listbizsymbolsmartupload.includes(
        //     this.dataAccountActive.business_info.taxid
        //   )
        // ) {
        // } else {
        if (this.detailstemplate.aspect_data) {
          for (let i = 0; i < this.detailstemplate.aspect_data.length; i++) {
            if (
              this.detailstemplate.aspect_data[i]
              .aspect_fix_value.length > 0
              // .aspect_fix_value !== "{}"
            ) {
              if (this.detailstemplate.aspect_data[i].aspect_null !== "Y") {
                this.detailstemplate.aspect_data[i].aspect_fix_value.push(
                  "notselecttemplate"
                );
                this.detailstemplate.aspect_data[
                  i
                ].value = this.detailstemplate.aspect_data[i].aspect_fix_value[
                  this.detailstemplate.aspect_data[i].aspect_fix_value.length -
                    1
                ];
                this.detailstemplate.aspect_data[i].fixvalue = "notuse";
              } else {
                this.detailstemplate.aspect_data[
                  i
                ].value = this.detailstemplate.aspect_data[
                  i
                ].aspect_fix_value[0];
                this.detailstemplate.aspect_data[i].fixvalue = "notuse";
              }
            } else {
              if (this.detailstemplate.aspect_data[i].aspect_null === "Y") {
                this.detailstemplate.aspect_data[i].fixvalue = "";
              } else {
                this.detailstemplate.aspect_data[i].value = "";
                this.detailstemplate.aspect_data[i].fixvalue = "notuse";
              }
            }
          }
        }
        this.$v.$reset();
        setTimeout(() => {
          this.loadingdatatemplate = true;
          // หน่วงเวลาตอนปิด dialog
        }, 200);
        // }
      } catch (err) {
        console.log("SomeError But It's fine");
      }
    },
    cleardataNoclose() {
      this.statusuploading = false;
      this.createprogress = false;
      this.selectingimportFiles = false;
      this.fileImport = null;
      this.percentfileuploading = 0;
      this.arraydataupload = [];
      this.countupload = 0;
      this.checkstatusduplicate = false;
      this.totalstackfile = 0;
      this.temparraydataupload = [];
      this.stackfileloading = false;
      this.userdata = {};
      this.uniqueidfile = 0;
      this.checkdetailmobile = false;
    },
    closeClearData() {
      setTimeout(() => {
        this.cleardata();
      }, 1000);
    },
  },
  mounted() {
    this.createprogress = false;
  },
  beforeDestroy() {
    this.removeEventUploadfiles();
    this.cleardata();
    this.createprogress = false;
  },
};
</script>
<style scoped>
.v-input.textFieldClass input {
  font-size: 14px;
  max-height: 90px;
  padding-bottom: 11px;
  padding-top: 11px;
}
.v-text-field.textFieldClass .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.checkboxStyle .v-label {
  font-size: 14px;
  color: black;
}
.textFieldClass .v-label {
  font-size: 14px;
  display: flex !important;
}
#titleTextUploadtitle {
  color: black;
  font-size: 18px;
  line-height: 24px;
}
#titleTextUploadsubtitle {
  color: red;
  font-size: 16px;
  line-height: 24px;
}
#inputuploadfiles {
  border-radius: 23px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
}
</style>
